{
  "welcome-header": "Welkom bij Magnit",
  "welcome-text-1": "Regel al jouw zaken online. Log in voor opdrachten, screenings, urenstaten, contracten en facturen.",
  "welcome-text-2": "Altijd en overal toegankelijk. Magnit, the Evolution of Work.",
  "login-header": "Inloggen",
  "login-text": "Volgende",
  "login-supplier": "Login als leverancier, zzp'er of deskundige",
  "login-customer": "Login als opdrachtgever",
  "login-customer-migrated": "Login als opdrachtgever (gemigreerd)",
  "login-customer-not-migrated": "Login als opdrachtgever (niet gemigreerd)",
  "login-netive": "Inloggen voor één van de volgende organisaties",
  "register-header": "Nog geen account?",
  "register-text": "Schrijf je in om direct toegang te krijgen tot de Magnit portal.",
  "register-button": "Schrijf je in",
  "questions-header": "Vragen?",
  "account": {
    "add-company-details": "Bedrijfsgegevens aanvullen",
    "edit-profile": "Profiel",
    "job-employee": "Medewerker",
    "logout": "Uitloggen",
    "profile": "Profiel",
    "switch": "Account wisselen"
  },
  "address": {
    "business": "Bedrijf",
    "postal": "Post"
  },
  "assignment": {
    "category-notstarted": "Niet gestart",
    "category-started": "Gestart",
    "category-stopped": "Beëindigd"
  },
  "assignment-contact": {
    "text-1": "Heb je vragen over deze opdracht? Voor vragen over een contract, een verlenging of functievereisten, kun je contact opnemen met <b>{{contactName}}</b> via <A HREF='mailto:{{contactEmailAddress}}'>{{contactEmailAddress}}</A>.",
    "text-2": "Voor alle andere vragen, kun je een supportticket indienen via ons <A HREF='https://support.magnitglobal.com/help' target='_blank'>Magnit Global Services and Support Center</A>, of bel ons supportteam via <A HREF='tel:+31 (0) 30 602 16 17'>+31 (0) 30 602 16 17</A> (maandag t/m vrijdag, van 9.00 tot 16.00 uur).",
    "text-3": "Voor vragen over vervallen facturen en onjuiste betalingen, kun je een supportticket indienen via ons <A HREF='https://support.magnitglobal.com/help' target='_blank'>Services and Support Center</A>.",
    "title": "Contact met Magnit"
  },
  "assignment-hours-and-expenses": {
    "expense-activity": "Activiteit",
    "expenses-column-tooltip": "Goedgekeurde onkosten (excl. btw). De concept-, ingediende- en/of afgekeurde onkosten staan tussen haakjes.",
    "expenses-title": "Onkosten",
    "hours-column-tooltip": "Goedgekeurde uren. De concept-, ingediende- en/of afgekeurde uren staan tussen haakjes.",
    "hours-title": "Uren",
    "no-data-yet": "Er zijn geen uren- en onkosten.",
    "no-records-for-period": "Er zijn (nog) geen uren en onkosten geschreven in deze periode.",
    "search-gave-no-records": "Er zijn op dit moment geen uren en onkosten die voldoen aan deze criteria.",
    "title": "Details uren & onkosten"
  },
  "assignment-insurance-page": {
    "button-more-info": "Meer info",
    "button-cancel": "Opzeggen",
    "cancel-modal": {
      "button-cancel-insurance": "Verzekering opzeggen",
      "button-do-not-cancel": "Niet opzeggen",
      "cancellation-date": "Je annulering gaat in op:",
      "cancellation-successful": "Je verzekering wordt beëindigd per {{cancellationDate}}. Laat meteen je nieuwe verzekering controleren via de verzekeringscheck-in bij Alicia, toegankelijk via de knop op deze pagina.",
      "required-checkbox-text-1": "Ik begrijp dat ik tijdens mijn opdracht via Magnit voldoende verzekerd moet zijn, ook na het opzeggen van de Alicia-verzekering.",
      "required-checkbox-text-2": "Ik begrijp dat Magnit genoodzaakt kan zijn om de samenwerking te beëindigen in het geval ik niet kan aantonen dat ik voldoende verzekerd ben.",
      "text": "Magnit vraagt al haar leveranciers om voldoende verzekerd te zijn voor het werk dat ze doen. Door je huidige verzekering van Alicia te annuleren, vragen we je opnieuw aan te tonen dat je een andere beroeps- en bedrijfsaansprakelijkheidsverzekering hebt. Voldoende verzekerd zijn is een belangrijke voorwaarde van de overeenkomst.<br><br>De annulering zal ingaan op de eerste van de maand die je hieronder aangeeft (waarbij de annuleringsdatum minimaal 14 dagen in de toekomst moet liggen). Op dat moment ben je niet meer via Alicia verzekerd voor je opdracht. <b>LET OP</b>: Je zal dus <u>vóór die datum</u> een nieuwe verzekering moeten aanleveren.<br><br>Weet je zeker dat je je huidige verzekering van Alicia wilt opzeggen?",
      "title": "Bevestig opzegging"
    },
    "header": "Beroeps- en bedrijfsaansprakelijkheidsverzekering",
    "policy-end-date": "Einddatum",
    "policy-number": "Polisnummer",
    "policy-number-tooltip": "Helaas kan dit veld momenteel niet gevuld worden. Kijk voor het polisnummer op het polisblad van Alicia.",
    "policy-status": "Status",
    "policy-status-active": "Actief",
    "policy-status-inactive": "Inactief",
    "policy-status-not-applicable": "Niet van toepassing",
    "policy-start-date": "Startdatum",
    "text-alicia-insurance": "Voor deze opdracht heb je ervoor gekozen je te verzekeren voor bedrijfsaansprakelijkheid en beroepsaansprakelijkheid via onze partner Alicia.\n\nDoor op de knop hieronder te klikken, ga je naar de informatiepagina over Alicia. Daar vind je meer informatie over de verzekering.",
    "text-no-insurance": "Op dit moment heb je je (nog) niet verzekerd via onze partner Alicia voor deze opdracht voor beroeps- en bedrijfsaansprakelijkheid, maar op een andere manier.\n\nWil je meer weten over de verzekeringen die Alicia je biedt? Klik dan op de knop hieronder."
  },
  "assignment-invoices-page": {
    "early-payments-information": "Meer informatie over versneld betalen"
  },
  "assignment-overview-page": {
    "agreed-terms": "Voorwaarden geaccepteerd",
    "column-assignment-number": "Opdrachtnummer",
    "column-candidate": "Deskundige en functie",
    "column-contract-action": "Contract (actie benodigd)",
    "column-customer": "Opdrachtgever",
    "column-enddate": "Einddatum",
    "column-hire-number": "Inhuurnummer",
    "column-number": "Aanvraagnummer",
    "column-remarks": "Bijzonderheden",
    "column-screening-action": "Screening (actie benodigd)",
    "column-startdate": "Startdatum",
    "column-tofollow": "Datum volgt",
    "complete-company-details": "Bedrijfsgegevens aanvullen",
    "contact": "Contact",
    "contracts": "Contracten",
    "fee-agreement-applicable": "Fee Overeenkomst",
    "hours": "Urenrapportage",
    "hours-per-week": "Uren per week",
    "incomplete-company-details": "De bedrijfsgegevens zijn niet compleet. Zonder deze gegevens en een afgeronde screening kan er geen contract worden opgesteld. Vul daarom deze gegevens zo snel mogelijk aan.",
    "incomplete-company-details-contact-brainnet": "De bedrijfsgegevens zijn niet compleet. Neem contact op met het Client Services Team via de contactgegevens op de opdracht.",
    "insurance": "Verzekering",
    "invoice": "Facturen",
    "invoices": "Facturen",
    "not-started": "Niet gestart",
    "payment-proposals": "Betaalvoorstellen",
    "period": "Periode",
    "personal-details": "Deskundige gegevens",
    "place-of-work": "Plaats werkzaamheden",
    "plus-package": "<b class='plus-sign'>+&hairsp;</b> Opdracht",
    "professional-personal-details": "Persoonlijke gegevens van de Deskundige",
    "screening": "Screening",
    "specifics": "Bijzonderheden",
    "supplier-contact": "Screening toegewezen aan",
    "supplier-name": "Leverancier",
    "tab-contact": "Contact",
    "tab-contracts": "Contracten",
    "tab-insurance": "Verzekering",
    "tab-invoices": "Facturen",
    "tab-overview": "Overzicht",
    "tab-payment-proposals": "Betaalvoorstellen",
    "tab-professional": "Deskundige gegevens",
    "tab-screening": "Screening",
    "title": "Opdracht",
    "title-prefixed": "Jouw opdracht",
    "type-of-assignment": "Type opdracht"
  },
  "assignment-overview-screening-page": {
    "accepted": "Geaccepteerd",
    "action-required": "Actie vereist",
    "action-required-professional": "In behandeling of actie vereist door Deskundige",
    "action-required-professional-text": "Dit onderdeel is in behandeling bij Magnit of moet worden opgepakt door de Deskundige.",
    "action-required-text": "Voor dit onderdeel is een actie nodig.",
    "add-personal-details": "Deskundige gegevens aanvullen",
    "appointment-scheduled": "Afspraak is gemaakt, wacht op identiteitscontrole",
    "approved": "Goedgekeurd",
    "completed": "Afgerond",
    "completed-on": "Afgerond op",
    "completed-text": "Dit onderdeel is gecontroleerd en geaccepteerd door Magnit en de opdrachtgever.",
    "complete-personal-details": "Vul persoonsgegevens aan",
    "correct-information": "Corrigeer de aangeleverde informatie",
    "explanation-title": "Toelichting",
    "in-process": "In behandeling",
    "in-progress": "In behandeling bij Magnit",
    "in-progress-text": "Dit onderdeel is in behandeling bij Magnit.",
    "in-review": "Aangeleverde informatie wordt gecontroleerd door Magnit",
    "no-screening-yet": "Er is (nog) geen screening voor deze opdracht.",
    "not-provided": "Nog aan te leveren",
    "privacy": "Voordat er gestart kan worden met de opdracht voeren wij, namens de opdrachtgever, een screening uit. Een goede screening is noodzakelijk om te controleren of de Deskundige daadwerkelijk beschikt over de juiste kwalificaties, papieren, diploma's, gecontroleerde referenties, etc. Wij controleren hierop om te voorkomen dat de opdrachtgevers voor verrassingen komen te staan.\n\nOm de screening uit te voeren, zijn er een aantal persoonsgegevens nodig. Privacy staat bij Magnit hoog in het vaandel, maar het is niet mogelijk om zonder deze gegevens te screening te voltooien. Hoe Magnit omgaat met persoonsgegevens is na te lezen in ons",
    "privacy-conclusion": "\n\nDe informatie en documenten die aangeleverd moeten worden, zijn te vinden onder ‘Screening’. Een deel hiervan moet aangeleverd worden door de Deskundige. Daarom is er de optie om deze informatie en documenten direct door de Deskundige digitaal aan te laten leveren. Door rechtsboven te klikken op ‘Delen met Deskundige’, wordt er e-mail verstuurd naar de Deskundige. Hierin wordt de Deskundige verzocht om de screening digitaal af te handelen.\n\nIn deze omgeving kun je zien welke informatie al is aangeleverd. Ook is te zien voor welk onderdeel nog een actie vereist is en wie deze actie moet uitvoeren. Wat de verschillende icoontjes betekenen, staat hieronder uitgelegd:",
    "privacy-conclusion-professional": "\n\nIn deze omgeving kun je zien welke informatie al is aangeleverd. Ook is te zien voor welk onderdeel nog een actie vereist is. Wat de verschillende icoontjes betekenen, staat hieronder uitgelegd:",
    "privacy-professional": "Voordat er gestart kan worden met de opdracht voeren wij, namens de opdrachtgever, een screening uit. Een goede screening is noodzakelijk om te controleren of jij daadwerkelijk beschikt over de juiste kwalificaties, papieren, diploma's, gecontroleerde referenties, etc. Wij controleren hierop om te voorkomen dat de opdrachtgevers voor verrassingen komen te staan.\n\nOm de screening uit te voeren, zijn er een aantal persoonsgegevens nodig. Privacy staat bij Magnit hoog in het vaandel, maar het is niet mogelijk om zonder deze gegevens te screening te voltooien. Hoe Magnit omgaat met persoonsgegevens is na te lezen in ons",
    "privacy-statement": "privacystatement",
    "rejected-action-required": "Afgekeurd, actie vereist",
    "rejected-action-required-text": "Dit onderdeel is gecontroleerd en afgekeurd door Magnit. Er is een aanvullende actie nodig van jou of de Deskundige.",
    "rejected-action-required-text-professional": "Dit onderdeel is gecontroleerd en afgekeurd door Magnit. Er is een aanvullende actie nodig.",
    "screening-delegation-failed": "De Deskundige is <u>niet</u> uitgenodigd. Probeer het opnieuw.",
    "screening-delegation-succeeded": "De Deskundige wordt uitgenodigd om de screening digitaal af te handelen.",
    "screening-documents": "Screening documenten",
    "screening-not-online": "Deze screening wordt niet uitgevoerd via de portal.",
    "share-with-candidate": "Delen met Deskundige",
    "translations-disclaimer": "&nbsp;",
    "vertical-tab-explanation": "Toelichting",
    "waiting-approval": "Aangeleverde informatie wordt gecontroleerd door Magnit",
    "waiting-integrity-statements": "Wacht op betrouwbaarheidsverklaringen van ex-werkgevers",
    "waiting-professional-details": "Vul eerst de Deskundige gegevens aan"
  },
  "assignments-page": {
    "tab-notstarted": "Niet gestart",
    "tab-started": "Lopend",
    "tab-stopped": "Beeïndigd",
    "title": "Opdrachten"
  },
  "billingperiod": {
    "four-four-five": "4-4-5",
    "four-weeks": "4-Wekelijkse periode",
    "month": "Maand",
    "quarter": "Kwartaal",
    "six-months": "Half jaar",
    "two-weeks": "2-Wekelijkse periode",
    "week": "Week",
    "year": "Jaar"
  },
  "billing-period-filter": {
    "current-period": "Huidige periode",
    "custom-period": "Zelf in te stellen",
    "divergent-billing-period-type-alert": "<b>Let op:</b> Deze opdracht wordt gefactureerd per <b>{{billingPeriod}}</b>.",
    "number": "Nummer",
    "period": "Periode",
    "specific-period": "Specifieke periode",
    "up-to-and-including": "Tot en met"
  },
  "common": {
    "action-cannot-be-undone": "Dit kan niet meer ongedaan worden gemaakt.",
    "add-document": "Voeg nog een document toe",
    "amount-including-vat": "Bedrag (incl. BTW)",
    "archived": "Gearchiveerd",
    "are-you-sure": "Weet je het zeker?",
    "asap": "Z.S.M.",
    "assignment": "Opdracht",
    "back": "Terug",
    "cancel": "Annuleren",
    "change": "Wijzigen",
    "characters": "tekens",
    "close": "Sluiten",
    "closed": "Gesloten",
    "continue": "Doorgaan",
    "correction": "Corrigeren",
    "date": "Datum",
    "date-created": "Datum aangemaakt",
    "days": "dagen",
    "deadline": "Deadline",
    "delete": "Verwijderen",
    "download": "Download",
    "download-all-documents": "Download alle documenten",
    "edit": "Wijzigen",
    "expired": "Verlopen",
    "explanation": "Toelichting",
    "file-invalid": "Het bestand kon niet worden geupload.",
    "file-size-too-large": "Het bestand is te groot.",
    "file-type-not-allowed": "Bestandstype niet toegestaan",
    "friday": "Vrijdag",
    "from": "Vanaf",
    "hour": "uur",
    "hourly-rate": "Zo scherp mogelijk",
    "invite": "Uitnodigen",
    "monday": "Maandag",
    "month-1": "Januari",
    "month-10": "Oktober",
    "month-11": "November",
    "month-12": "December",
    "month-2": "Februari",
    "month-3": "Maart",
    "month-4": "April",
    "month-5": "Mei",
    "month-6": "Juni",
    "month-7": "Juli",
    "month-8": "Augustus",
    "month-9": "September",
    "next": "Volgende",
    "no": "Nee",
    "no-results": "Geen resultaten gevonden",
    "not-acquired": "n.v.t.",
    "not-applicable": "Niet van toepassing",
    "not-applicable-abbreviation": "N.v.t.",
    "ok": "Ok",
    "period": "Periode",
    "postal-code-invalid": "Onjuiste notatie (bijv. 1234 AB)",
    "previous": "Vorige",
    "provided-information": "Verstrekte informatie",
    "results-shown": "Getoonde resultaten",
    "return-to-overview": "Terug naar overzicht",
    "revert": "Terughalen",
    "saturday": "Zaterdag",
    "save": "Opslaan",
    "search": "Zoeken",
    "send": "Versturen",
    "settings": "Instellingen",
    "skip": "Overslaan",
    "something-went-wrong": "Er is iets misgegaan.",
    "something-went-wrong-try-again-or-contact-us": "Er is helaas is iets misgegaan. Probeer het later nog eens of neem <a href='/supplier/support/contact'>contact op</a> met Magnit.",
    "status": "Status",
    "status-approved": "Goedgekeurd",
    "status-concept": "Concept",
    "status-rejected": "Afgekeurd",
    "status-submitted": "Ingediend",
    "sunday": "Zondag",
    "tba": "n.n.b.",
    "thursday": "Donderdag",
    "till": "t/m",
    "to": "Tot",
    "to-follow": "Volgt",
    "tooltip-contact-brainnet-for-changes": "Dit kan niet gewijzigd worden. Neem contact op met Magnit wanneer een wijziging nodig is.",
    "total-amount-week": "Totaal bedrag wk",
    "total-today": "Totaal vandaag",
    "total-week": "Totaal wk",
    "tuesday": "Dinsdag",
    "unauthorised": "Vanwege veiligheidsredenen ben je automatisch uitgelogd. Ververs de pagina om opnieuw in te loggen.",
    "unknown": "Onbekend",
    "unspecified": "Niet gespecificeerd",
    "upload": "Upload",
    "verify-and-send": "Controleren en verzenden",
    "waiting-for-approval": "Wacht op goedkeuring",
    "wednesday": "Woensdag",
    "week": "Week",
    "yes": "Ja"
  },
  "company-information": {
    "business-organisation": {
      "delivers-professionals": "Mijn bedrijf levert professionals",
      "self-employed": "Ik ben zelfstandige"
    },
    "complete": "Compleet",
    "incomplete": "Bedrijfsgegevens incompleet",
    "rejected": "Bedrijfsgegevens afgekeurd"
  },
  "contactperson": {
    "financial": "Financieel",
    "main": "Hoofd",
    "signer": "Ondertekenaar"
  },
  "contractactions": {
    "afgerond": "Completed",
    "brainnet": "Magnit",
    "complete": "Afgerond",
    "supplier": "Leverancier",
    "unspecified": "Niet gespecificeerd",
    "waiting-on-screening": "Wacht op screening"
  },
  "contracts": {
    "active-contracts": "Actieve contracten",
    "attachments": "Bijlagen",
    "client": "Opdrachtgever",
    "client-name": "Opdrachtgever",
    "contract-details": "Contractdetails",
    "contract-number": "Contractnummer",
    "contract-period": "Periode (van/tot)",
    "contracts": "Contracten",
    "contracts-to-sign": "Te tekenen contracten",
    "contract-type": "Contracttype",
    "contract-types": {
      "contract-period-agreement": "Werkopdracht",
      "model-agreement": "Overeenkomst van Opdracht",
      "service-agreement": "Inleenovereenkomst"
    },
    "date-signed": "Datum ondertekend",
    "details": "Bijzonderheden",
    "download-contract": "Contract downloaden",
    "download-entrepreneurs-assessment": "Download ondernemerstoets",
    "drawn-up": "Opgesteld op",
    "expert-name": "Deskundige",
    "expired-contracts": "Beëindigde contracten",
    "no-active-contracts-available": "Er zijn geen actieve contracten.",
    "no-contracts-available-for-this-assignment": "Er zijn (nog) geen contracten voor deze opdracht.",
    "no-contracts-found": "Er zijn geen contracten die voldoen aan deze criteria.",
    "no-expired-contracts-available": "Er zijn geen beëindigde contracten.",
    "no-signed-contracts-available": "Er zijn geen getekende contracten.",
    "no-unsigned-contracts-available": "Er zijn geen contracten die getekend moeten worden.",
    "sign-before": "Ondertekenen voor",
    "sign-contract": "Contract ondertekenen",
    "signed-by": "Ondertekend door",
    "signed-contracts": "Getekende contracten",
    "signed-on": "Getekend op",
    "signing-blocked-by-missing-coc-number-error": "Om dit contract te ondertekenen hebben wij eerst jouw kvk nummer nodig.",
    "signing-status": {
      "ready-for-signing": "Wacht op ondertekenen",
      "signed": "Ondertekend",
      "waiting-for-approval": "Wacht op goedkeuring"
    },
    "to-assignment": "Naar opdracht",
    "unsigned-contracts": "Te tekenen contracten"
  },
  "contracts-sign-before-date": "Ondertekenen voor datum:",
  "create-candidate": {
    "city": "Woonplaats",
    "country": "Land",
    "dateofbirth": "Geboortedatum",
    "firstname": "Roepnaam",
    "firstnames": "Voornamen",
    "initials": "Initialen",
    "lastname": "Achternaam",
    "nationality": "Nationaliteit",
    "personal-email": "Persoonlijk e-mailadres van de kandidaat",
    "postalcode": "Postcode",
    "prefix": "Tussenvoegsel(s) (optioneel)",
    "title": "Kandidaat aanmaken"
  },
  "datatable": {
    "assignments-empty-data": "Er zijn geen opdrachten die voldoen aan deze criteria",
    "assignments-no-ended-data": "Er zijn op dit moment geen beëindigde opdrachten.",
    "assignments-no-ended-data-title": "Beëindige opdrachten",
    "assignments-no-notstarted-data": "Er zijn op dit moment geen niet gestarte opdrachten.",
    "assignments-no-notstarted-data-title": "Niet gestarte opdrachten",
    "assignments-no-started-data": "Er zijn op dit moment geen lopende opdrachten.",
    "assignments-no-started-data-title": "Lopende opdrachten",
    "jobrequests-archived-empty-data": "Er zijn op dit moment geen gearchiveerde aanbiedingen die voldoen aan deze criteria.",
    "jobrequests-favourite-empty-data": "Er zijn op dit moment geen favoriete aanvragen die voldoen aan deze criteria.",
    "jobrequests-hidden-empty-data": "Er zijn op dit moment geen verborgen aanvragen die voldoen aan deze criteria.",
    "jobrequests-new-empty-data": "Er zijn op dit moment geen nieuwe aanvragen die voldoen aan deze criteria.",
    "jobrequests-no-archived-data": "Er zijn nog geen gearchiveerde aanbiedingen.",
    "jobrequests-no-drafts-data": "Er zijn op dit moment geen concept aanbiedingen.",
    "jobrequests-no-favorite-data": "Er zijn op dit moment geen favoriete aanvragen. Markeer een aanvraag als favoriet om de aanvraag in deze lijst terug te zien.",
    "jobrequests-no-hidden-data": "Er zijn op dit moment geen verborgen aanvragen.",
    "jobrequests-no-new-data": "Er zijn op dit moment geen nieuwe aanvragen.",
    "jobrequests-no-proposed-data": "Er zijn nog geen kandidaten aangeboden op aanvragen, of de aanbieding is al gearchiveerd.",
    "jobrequests-proposed-empty-data": "Er zijn geen aangeboden kandidaten die voldoen aan deze criteria.",
    "no-data": "Er is geen data gevonden"
  },
  "delegate-screening": {
    "company-candidate-relationship": "Wat is de arbeidsrelatie tussen de Deskundige en jouw bedrijf?",
    "invite-candidate": "Nodig de Deskundige uit om de screeningsinformatie digitaal aan te leveren. Je kunt de voortgang via de portal volgen.",
    "personal-address": "Let op: Vul hier een persoonlijk e-mailadres in dat voor, tijdens en na de opdracht geldig is.",
    "title": "Delen met Deskundige"
  },
  "detailed-explanation-of-the-professional-and-corporate-liability-risk": "Uitgebreide toelichting op het beroeps- en bedrijfsaansprakelijkheidsrisico",
  "download-hours-expenses-report-modal": {
    "download-choice": "Welk rapport wil je downloaden?",
    "download-choice-both": "Beide rapportages",
    "download-choice-expenses": "Onkostenrapportage",
    "download-choice-hours": "Urenrapportage",
    "download-title": "Download uren- en onkostenrapportage"
  },
  "draftproposalstatus": {
    "closed": "Aanvraag verlopen",
    "concept": "Concept aanbieding"
  },
  "employments-page": {
    "tab-all": "Alle inhuur",
    "tab-notstarted": "Niet gestart",
    "tab-started": "Lopend",
    "tab-stopped": "Beeïndigd"
  },
  "employment-type-options": {
    "employee": "Loondienst (de Deskundige is bij jouw bedrijf in loondienst)",
    "employee-short": "Loondienst",
    "zzp-direct": "Zzp direct (Je bent zelf zzp’er)",
    "zzp-direct-short": "Zzp direct",
    "zzp-indirect": "Zzp indirect (de Deskundige is een zzp’er en wordt ingehuurd door jouw bedrijf)",
    "zzp-indirect-short": "Zzp indirect"
  },
  "enrich-candidate": {
    "title": "Voeg Deskundige gegevens toe"
  },
  "entrepreneurial-assessment": {
    "are-you-entrepreneur": "Bent u een ondernemer?",
    "city": "Plaats",
    "complete-entrepreneurial-assessment": "Ondernemerstoets invullen",
    "contract-signing-failed": "Het contract is niet ondertekend. Heeft u een vraag? Neem contact op met het Client Services Team via de contactgegevens op de opdracht.",
    "contract-signing-succeeded": "Het contract is ondertekend en zal door Magnit worden gecontroleerd.",
    "declaration": "Verklaring",
    "entrepreneurial-assessment": "Ondernemerstoets",
    "position": "Functie",
    "question1a": "Bent u ondernemer?",
    "question1a-tooltip": "U bent ondernemer als u (als zzp'er) ingeschreven bent bij de Kamer van Koophandel.",
    "question1b": "Sinds welk jaar bent u ondernemer?",
    "question1b-placeholder": "Bijv. 1996",
    "question1b-tooltip": "Het jaar waarin uw onderneming is opgericht (dit is ook terug te vinden op het uittreksel van de Kamer van Koophandel)",
    "question2": "Beschikt u over kapitaal in de vorm van geld en/of goederen?",
    "question2-tooltip": "Zodra u werkzaamheden heeft verricht en hiervoor een factuur stuurt, beschikt u over kapitaal in de vorm van geld. Indien u beschikt over eigen materiaal, bezit u kapitaal in de vorm van goederen.",
    "question3": "Loopt u 'debiteurenrisico'?",
    "question3-tooltip": "Als ondernemer loopt u het risico (debiteurenrisico) dat uw facturen niet (of niet op tijd) betaald worden, bijvoorbeeld als de klant failliet gaat of vanwege een juridisch/zakelijk geschil met de klant.",
    "question4": "Gebruikt u uw naam voor de uitoefening van uw werkzaamheden?",
    "question4-tooltip": "Een ondernemer wordt gevraagd vanwege een specifieke expertise/dienst en het vertrouwen (reputatie) dat deze dienst tegen de juiste kwaliteit en kosten wordt geleverd.",
    "question5": "Bent u afhankelijk van de vraag naar uw expertise, producten of diensten?",
    "question5-tooltip": "Een ondernemer is voor zijn/haar inkomsten afhankelijk van de vraag van klanten.",
    "question6": "Bent u aansprakelijk voor eventuele schulden van uw onderneming?",
    "question6-tooltip": "Een ondernemer is verantwoordelijk voor alles wat hij/zij doet voortvloeiende uit zijn/haar ondernemingsactiviteiten.",
    "question7": "Houdt u een (financiële) administratie bij of laat u deze bijhouden?",
    "question7-tooltip": "Een ondernemer is bij wet verplicht een administratie bij te (laten) houden.",
    "spplier-contact-statement": "Hierbij verklaar ik, <b>{{username}}</b>, dat de Ondernemerstoets naar waarheid is ingevuld:",
    "supplier-statement": "{{supplier}}, statutair gevestigd en kantoorhoudende te {{location}}, ingeschreven bij de Kamer van Koophandel onder nummer {{chamberOfCommerceNumber}}, rechtsgeldig vertegenwoordigd door haar bestuurder(s), verklaart het volgende:",
    "why": "De Ondernemerstoets maakt integraal onderdeel uit van de Overeenkomst van Opdracht, daarom vragen wij u deze Ondernemerstoets in te vullen.",
    "you-answered-no": "U heeft een vraag met 'nee' beantwoord. Neem contact op met het Client Services Team via de contactgegevens op de opdracht. "
  },
  "error": {
    "account-locked-header": "Dit account is tijdelijk geblokkeerd",
    "account-locked-text": "Dit account is tijdelijk geblokkeerd, waardoor het niet mogelijk is om in te loggen. Probeer het op een later moment nogmaals of neem contact op met Magnit. Dit kan telefonisch via <A HREF='tel:+31 (0) 30 602 16 17'>+31 (0) 30 602 16 17</A> of stuur een e-mail naar <A HREF='mailto:infoNL@magnitglobal.com'>infoNL@magnitglobal.com</A>.",
    "combination-already-exists-add-explanation": "Deze combinatie bestaat al, vul een toelichting in.",
    "combination-already-exists-add-other-explanation": "Deze combinatie bestaat al, vul een andere toelichting in.",
    "date-before-max-months-in-past": "Het is niet mogelijk om langer dan {{months}} maanden in het verleden onkosten te declareren.",
    "date-not-in-selected-period": "De geselecteerde datum is buiten de contractperiode.",
    "date-not-in-selected-week": "De gekozen datum ligt buiten de geselecteerde week. Kies een andere week.",
    "duplicate-email-address": "Dit e-mailadres is al ingevuld.",
    "expense-greater-than-max-amount": "Je kunt maximaal € 2500,- declareren.",
    "expense-smaller-than-min-amount": "Bedrag moet groter zijn dan 0.",
    "general": "Er is iets misgegaan",
    "incorrect-notation": "Onjuiste notatie",
    "kilometers-less-than-min": "Aantal kilometers moet groter zijn dan 0.",
    "length": "Aantal ingevoerde karakters niet juist",
    "maxLength": "Max. aantal karakters overschreden",
    "minLength": "Niet genoeg karakters ingevoerd",
    "notEqualTo": "Veld is verplicht",
    "olderThan18": "De minimum leeftijd is 18 jaar",
    "pattern": "Onjuist karakter ingevoerd",
    "profile-supplier": {
      "coc-length-error": "KvK nummer moet bestaan uit 8 nummers",
      "coc-number-error": "Het ingevulde KvK-nummer is niet correct",
      "coc-registration-end-date-in-past": "Het bedrijf dat bij dit KvK-nummer hoort, is niet meer ingeschreven bij de Kamer van Koophandel."
    },
    "repeated-email-does-not-match": "Het e-mailadres komt niet overeen",
    "required": "Veld is verplicht",
    "requiredTrue": "Veld is verplicht",
    "screening-big-check-page": {
      "big-number-length-error": "Het BIG nummer moet bestaan uit 11 cijfers. Als het BIG nummer dat je invult minder dan 11 cijfers lang is, voeg dan nullen toe vòòr het initiele nummer totdat het in totaal 11 cijfers bevat"
    },
    "something-went-wrong-try-again-or-contact-us": "Er is helaas is iets misgegaan. Probeer het later nog eens of neem <a href='/supplier/support/contact'>contact op</a> met Magnit.",
    "zzp-employmenttype-not-allowed": "Als jij zelf de opdracht gaat uitvoeren, moet je zelf de screeningsinformatie aanleveren. Welke informatie en documenten dit zijn, vind je onder ‘Screening’."
  },
  "expense-edit-page": {
    "delivery-date-kilometer": "Reisdatum",
    "delivery-date-otherexpense": "Datum uitgave",
    "description": "Omschrijving (optioneel)",
    "expense-status-approved": "Goedgekeurd",
    "expense-status-draft": "Concept",
    "expense-status-rejected": "Afgekeurd",
    "expense-status-submitted": "Ingediend",
    "label-actions": "Acties",
    "label-costcenters": "Kostenplaats",
    "label-description": "Omschrijving",
    "label-expense-amount": "Bedrag excl. btw",
    "label-expense-quantity": "Aantal kilometers",
    "label-products": "Activiteit",
    "label-product-type": "Soort declaratie",
    "label-specification": "Specificatie",
    "label-upload-receipt": "Upload bon",
    "popup-title-edit": "Wijzig declaratie",
    "popup-title-new": "Voeg nieuwe declaratie toe",
    "product-type-kilometer": "Kilometers declareren",
    "product-type-other-expense": "Onkosten declareren",
    "select-activity": "Kies activiteit",
    "select-costcenter": "Kies kostenplaats"
  },
  "expenses-edit-page": {
    "button-add-declaration": "Declaratie toevoegen",
    "button-submit": "Indienen",
    "column-amount": "Bedrag",
    "column-costcenter": "Kostenplaats",
    "column-date": "Datum",
    "column-deliverydate": "Datum uitgave",
    "column-producttype": "Activiteit",
    "column-total": "Totaal",
    "column-total-amount": "Totaal bedrag",
    "date-seven-days-in-future": "Het is niet mogelijk om verder dan 1 week in de toekomst onkosten te declareren.",
    "document-uploaded": "De bon is geüpload.",
    "error-max-amount-warning": "Je kunt per declaratie maximaal € 2500,- indienen.",
    "expense-description": "Omschrijving",
    "expense-details": "Specificatie",
    "expense-removed": "De onkosten zijn verwijderd.",
    "expenses-reverted": "De ingediende onkosten zijn teruggehaald.",
    "expenses-submitted": "De onkosten zijn ingediend.",
    "expenses-submitted-week": "De onkosten voor week {{week}} zijn ingediend.",
    "info-no-expenses": "Er zijn nog geen onkosten gedeclareerd op deze dag. Voeg een declaratie toe.",
    "label-new": "<Nieuwe declaratie>",
    "label-total": "Totaal",
    "label-total-week": "Totaal deze week",
    "navigate-to-hours": "Uren schrijven",
    "no-expenses-yet": "Er zijn geen onkosten gedeclareerd in deze week.",
    "no-hours-yet": "Er zijn nog geen uren geschreven in deze week.",
    "saved-dont-forget-submit": "De onkosten zijn opgeslagen. Vergeet de onkosten niet in te dienen.",
    "saved-dont-forget-submit-week": "De onkosten voor week {{week}} zijn opgeslagen. Vergeet de onkosten niet in te dienen.",
    "title": "Onkosten declareren",
    "tooltip-ex-vat": "Gedeclareerd bedrag exclusief btw.",
    "withdraw": "Terughalen",
    "withdraw-submitted-expenses": "Ingediende onkosten terughalen",
    "withdraw-submitted-expenses-are-you-sure": "Weet je zeker dat je de ingediende onkosten wilt terughalen?"
  },
  "expenses-status-modal": {
    "bullet-1": "De volgende onkostenstatussen worden gehanteerd:",
    "bullet-2": "Je kunt alleen goedgekeurde onkosten factureren.",
    "bullet-3": "Goedgekeurde onkosten kunnen niet meer worden aangepast.",
    "bullet-4": "Ingediende onkosten moet je eerst terughalen, voordat je deze kunt aanpassen.",
    "bullet-5": "Afgekeurde onkosten kun je direct aanpassen en opnieuw indienen.",
    "title": "Meer informatie over onkosten declareren"
  },
  "expertises": {
    "analysis": "Analyse",
    "bank-insurance": "Bank / Verzekeren",
    "construction": "Bouw / Civiel",
    "development": "Development",
    "education": "Onderwijs",
    "energy": "Energie / Nuts",
    "finance": "Finance",
    "hr-staff-legal": "HR / Staff / Legal",
    "ict": "ICT",
    "infra": "Infra / Wegen",
    "installation": "Installatietechniek",
    "maintenance": "Beheer / Support",
    "mechanical": "WTB / Elektrotechniek / Industriële automatisering",
    "medical": "Medisch / QHSE",
    "other": "Diversen",
    "other-it": "Diversen (IT)",
    "sales": "Sales / MarCom",
    "social-enterprising": "Inclusief ondernemen",
    "water": "Water"
  },
  "file-drop": {
    "download-boundaries": "Bestandstype: .pdf, .xlsx, xls, .jpeg, .png, .doc of .docx | Maximale bestandsgrootte: 5MB.",
    "download-instruction": "Klik hier om een bestand te kiezen, of sleep het bestand hier naar toe."
  },
  "filters": {
    "candidate-status-title": "Kandidaat status",
    "contract-action-title": "Contract (actie benodigd)",
    "contract-filter": {
      "contract-expires-within": "Contract eindigt binnen",
      "contract-expires-within-one-month": "1 maand",
      "contract-expires-within-six-months": "6 maanden",
      "contract-expires-within-three-months": "3 maanden",
      "contract-expires-within-two-months": "2 maanden",
      "contract-is-expired": "Contract is beëindigd",
      "contract-is-expired-within-last-month": "Afgelopen maand",
      "contract-is-expired-within-last-six-months": "Afgelopen 6 maanden",
      "contract-is-expired-within-last-three-months": "Afgelopen 3 maanden",
      "contract-is-expired-within-last-two-months": "Afgelopen 2 maanden",
      "sign-contract-before": "Ondertekenen voor",
      "sign-contract-before-next-month": "Volgende maand",
      "sign-contract-before-next-two-weeks": "Volgende 2 weken",
      "sign-contract-before-next-week": "Volgende week",
      "sign-contract-before-tomorrow": "Morgen"
    },
    "date-created-in-past-title": "Datum aangemaakt in het verleden",
    "expertises-title": "Vakgebieden",
    "hours-per-week-title": "Uren per week",
    "invoice-date-title": "Factuurdatum in het verleden",
    "invoice-filter": {
      "credit": "Credit",
      "debet": "Debet",
      "in-eight-days": "8 dagen",
      "in-fifteen-days": "15 dagen",
      "in-sixty-days": "60 dagen",
      "in-thirty-days": "30 dagen",
      "one-month": "1 maand",
      "one-week": "1 week",
      "six-months": "6 maanden",
      "three-months": "3 maanden",
      "twelve-months": "12 maanden",
      "twenty-four-months": "24 maanden",
      "two-months": "2 maanden",
      "two-weeks": "2 weken"
    },
    "invoice-type-title": "Factuurtype",
    "last-change-title": "Laaste wijziging",
    "longer-than-two-days-ago": "Langer dan twee dagen geleden",
    "options-to-extent-title": "Optie tot verlenging",
    "option-to-extend-title": "Optie tot verlenging",
    "payment-date-title": "Betaaldatum binnen",
    "screening-action-title": "Screening (actie benodigd)",
    "search-text": "Zoeken",
    "self-employment-allowed-title": "Zzp'er toegestaan",
    "title": "Filters",
    "today": "Vandaag",
    "two-days-ago": "Twee dagen geleden",
    "yesterday": "Gisteren"
  },
  "filters-two-days-ago": "Twee dagen geleden",
  "function-requirements-profile": {
    "button": "Functievereistenprofiel",
    "download-instruction": "Voor deze functie zijn er specifieke vereisten van toepassing. Download hier het formulier en vul dit aan. Daarna kun je het ingevulde formulier weer uploaden.",
    "label-upload": "Upload het functievereistenprofiel",
    "skip-required": "Het functievereistenprofiel is voor deze aanvraag niet van toepassing.",
    "title": "Functievereisten profiel",
    "upload-success": "Het functievereistenprofiel is geüpload."
  },
  "gender": {
    "man": "Man",
    "title": "Geslacht",
    "woman": "Vrouw"
  },
  "home": {
    "approve-payment-proposals": "Betaalvoorstellen goedkeuren",
    "assignments": "Opdrachten",
    "categories": {
      "company-information": "Bedrijfsgegevens",
      "contract": "Contracten",
      "payment-proposal": "Betaalvoorstellen",
      "screening": "Screening"
    },
    "category": "Categorie",
    "client": "Opdrachtgever",
    "complete-company-information": "Bedrijfsgegevens aanvullen",
    "contact-brainnet": "Contact met Magnit",
    "declare-expenses": "Onkosten declareren",
    "details": "Bijzonderheden",
    "drafts": "Concepten",
    "expense-report": "Onkostenrapportage",
    "expert": "Deskundige",
    "expert-and-role": "Deskundige (Functie)",
    "favorites": "Favorieten",
    "finished": "Beëindigd",
    "go-to-assignment": "Naar opdracht",
    "go-to-assignments": "Naar alle opdrachten",
    "go-to-jobrequest": "Naar alle aanvragen",
    "hasnt-started-yet": "Niet gestart",
    "jobrequests": "Aanvragen",
    "my-oustanding-actions": "Mijn openstaande acties",
    "newest-jobrequests": "Nieuwste aanvragen",
    "new-jobrequests": "Nieuwe aanvragen",
    "no-new-jobrequests": "Er zijn geen nieuwe aanvragen.",
    "ongoing": "Lopend",
    "outstanding-action": "Openstaande actie",
    "outstanding-actions": "Openstaande acties",
    "outstanding-screening-items": "openstaande screening onderdelen",
    "perform-screenings": "Screeningen uitvoeren",
    "proposals": "Aanbiedingen",
    "proposed": "Aangeboden",
    "register-hours-worked": "Tijdschrijven",
    "role": "Functie",
    "sign-contracts": "Contracten ondertekenen",
    "there-are-no-outstanding-actions": "Er zijn geen openstaande acties",
    "timesheets": "Urenrapportage",
    "timesheets-and-expense-report": "Uren- en onkostenrapportage",
    "via": "via",
    "welcome": "Welkom",
    "with-contract-number": "met contractnummer",
    "your-assignment": "Jouw opdracht"
  },
  "hours": {
    "edit": {
      "max-hours-a-day-warning": "Het is niet mogelijk om meer dan {{maxAmount}} uur op één dag te schrijven.",
      "you-cant-register-hours-futher-than-one-week-in-future": "Het is niet mogelijk om verder dan 1 week in de toekomst uren te schrijven."
    }
  },
  "hours-and-expense": {
    "billable-product-status": {
      "approved": "Goedgekeurd",
      "concept": "Concept",
      "rejected": "Afgekeurd",
      "submitted": "Ingediend"
    }
  },
  "hours-and-expenses": {
    "amount": "Bedrag",
    "amount-column-tooltip": "Goedgekeurde uren maal het tarief (excl. btw).",
    "assignment-number": "Opdrachtnummer",
    "changes-are-not-saved": "Je wijzigingen zijn niet opgeslagen",
    "client-and-location": "Opdrachtgever",
    "declare-expenses": "Onkosten declareren",
    "download-expense-report": "Download onkostenrapportage",
    "download-hours-and-expenses-report": "Download uren- en onkostenrapportage",
    "download-hours-report": "Download urenrapportage",
    "expenses": "Onkosten",
    "expenses-column-tooltip": "Goedgekeurde onkosten (excl. btw). De ingediende en/of afgekeurde onkosten staan tussen haakjes.",
    "expenses-explanation": "Goedgekeurde onkosten (excl. btw). De concept-, ingediende- en/of afgekeurde onkosten staan tussen haakjes.",
    "expert-and-client": "Deskundige en Opdrachtgever",
    "from": "Van",
    "from-to": "Van/tot",
    "hours": "Uren",
    "hours-and-expenses": "Uren en onkosten",
    "hours-column-tooltip": "Goedgekeurde uren. De ingediende en/of afgekeurde uren staan tussen haakjes.",
    "professional-and-position": "Deskundige en functie",
    "register-hours-worked": "Uren schrijven",
    "timesheets-and-expense-reports": "Uren & onkosten rapportage",
    "to": "Tot",
    "total": "Totaal",
    "total-amount": "Totaal bedrag",
    "total-amount-tooltip": "Goedgekeurde uren maal het tarief (excl. btw) en de goedgekeurde onkosten (excl. btw).",
    "week-number": "Weeknummer",
    "your-changes-will-be-lost": "Je verlaat het scherm zonder je wijzigingen op te slaan of in te dienen. De wijzigingen gaan verloren."
  },
  "hours-edit": {
    "actions": "Acties",
    "activity": "Activiteit",
    "add-activity": "Activiteit toevoegen",
    "button-change-activity": "Wijzig activiteit",
    "change-activity": "Activiteit wijzigen",
    "copy-previous-week": "Kopieer vorige week",
    "cost-center": "Kostenplaats",
    "customer-reference": "Klantreferentie",
    "day-is-outside-contract-period": "Deze dag valt buiten de contractperiode. Het is daarom niet mogelijk om uren te schrijven.",
    "declare-expenses": "Onkosten declareren",
    "duplicate-previous-week-no-hours": "Er zijn vorige week geen uren geschreven. Het is daardoor niet mogelijk om activiteiten te kopiëren.",
    "duplicate-previous-week-succeeded": "De activiteiten van de vorige week zijn gekopieerd. Voeg nu uren toe.",
    "duplicate-previous-week-tooltip": "Deze functie kopieert alle activiteiten uit de vorige week naar de huidige week. Voordat je dit kan opslaan of indienen, moet je nog uren toevoegen aan de activiteiten.",
    "hours": "Uren",
    "hours-are-saved": "De uren voor week {{weekNumber}} zijn opgeslagen. Vergeet de uren niet in te dienen.",
    "hours-are-submitted": "De uren voor week {{weekNumber}} zijn ingediend.",
    "hours-for-day-are-saved": "De uren voor {{day}} zijn opgeslagen. Vergeet de uren niet in te dienen.",
    "hours-for-day-are-submitted": "De uren voor {{day}} zijn ingediend.",
    "no-hours-yet-this-week": "Er zijn nog geen uren geschreven in deze week.<br>Voeg een <b>activiteit</b> toe of kopieer de activiteiten van vorige week.",
    "no-hours-yet-this-week-copy-previous-week-available": "Er zijn nog geen uren geschreven in deze week.<br>Voeg een <b>activiteit</b> toe of <b>kopieer de activiteiten van vorige week</b>.",
    "no-hours-yet-this-week-copy-previous-week-unavailable": "Er zijn nog geen uren geschreven deze week.<br>Voeg een <b>activiteit</b> toe.",
    "no-hours-yet-today": "Er zijn nog geen uren geschreven in deze week. Voeg een <b>activiteit</b> toe of kopieer de activiteiten van vorige week.",
    "no-hours-yet-today-copy-previous-week-available": "Er zijn nog geen uren geschreven. Voeg een <b>activiteit</b> toe of <b>kopieer de activiteiten van vorige week</b>.",
    "no-hours-yet-today-copy-previous-week-unavailable": "Er zijn nog geen uren geschreven.<br>Voeg een <b>activiteit</b> toe.",
    "note": "Toelichting",
    "rejected-by": "Afgekeurd door",
    "select-activity": "Kies activiteit",
    "select-costcenter": "Kies kostenplaats",
    "submit": "Indienen",
    "the-hours-are-deleted": "De uren zijn verwijderd.",
    "week-is-outside-contract-period": "Deze week valt buiten de contractperiode. Het is daarom niet mogelijk om uren te schrijven.",
    "week-is-too-far-in-history": "Het is niet mogelijk om langer dan {{maxMonthsInHistory}} maanden in het verleden uren te schrijven.",
    "withdraw": "Terughalen",
    "withdraw-hours": "Uren terughalen",
    "withdraw-submitted-hours": "Ingediende uren terughalen",
    "withdraw-submitted-hours-are-you-sure": "Weet je zeker dat je de ingediende uren wilt terughalen?",
    "withdraw-submitted-hours-succeeded": "De ingediende uren zijn teruggehaald.",
    "write-hours": "Uren schrijven"
  },
  "hours-status-modal": {
    "bullet-1": "De volgende urenstatussen worden gehanteerd:",
    "bullet-2": "Je kunt alleen goedgekeurde uren factureren.",
    "bullet-3": "Goedgekeurde uren kunnen niet meer worden aangepast.",
    "bullet-4": "Ingediende uren moet je eerst terughalen, voordat je deze kunt aanpassen.",
    "bullet-5": "Afgekeurde uren kun je direct aanpassen en opnieuw indienen.",
    "bullet-6": "Je kunt niet meer dan 24 uur schrijven op één dag.",
    "bullet-7": "Uren worden automatisch afgerond op eenheden van een kwartier.",
    "bullet-8": "Je kunt per week slechts één combinatie van activiteit en kostenplaats selecteren, zonder dat er een toelichting vereist is.",
    "bullet-assignment-expenses": "Je kunt alleen goedgekeurde onkosten factureren. De goedgekeurde onkosten zijn exclusief btw. De ingediende- en/of afgekeurde onkosten staan tussen haakjes.",
    "bullet-assignment-hours": "Je kunt alleen goedgekeurde uren factureren. De ingediende- en/of afgekeurde uren staan tussen haakjes.",
    "title": "Meer informatie over uren schrijven"
  },
  "information-delivery-status": {
    "date-received": "Datum ontvangen",
    "provided-by": "Verstrekt door",
    "reason-for-rejection": "Reden afwijzing",
    "review-date": "Datum beoordeling"
  },
  "invoice-page": {
    "additional-service": "Aanvullende dienst?",
    "additional-service-addendum": "Deze factuur wordt verrekend bij de betaling van openstaande posten",
    "button-download": "Factuur downloaden",
    "credit": "Credit",
    "debit": "Debet",
    "invoice-date": "Factuurdatum",
    "invoice-type": "Factuurtype",
    "payment-discount": "Betalingskorting",
    "payment-term": "Betaaltermijn",
    "remarks": "Opmerking op de factuur",
    "subheader": "Factuur details",
    "working-month": "Werkmaand",
    "working-period": "Werkperiode"
  },
  "invoices-page": {
    "column-additional-information": "Bijzonderheden",
    "column-amount": "Bedrag",
    "column-amount-and-payment-date": "Bedrag en betaaldatum",
    "column-customer": "Opdrachtgever",
    "column-download-available": {
      "to-follow": "Volgt"
    },
    "column-download-available-header": "Download beschikbaar?",
    "column-expert-and-client": "Deskundige en Opdrachtgever",
    "column-invoice-number": "Factuurnummer",
    "column-payment-date": "Betaaldatum",
    "column-professional": "Deskundige",
    "column-to-receive-header": "Te ontvangen",
    "column-to-settle-header": "Te verrekenen",
    "column-working-month": "Werkmaand",
    "credit-invoice": "Creditfactuur",
    "no-records": "Er zijn (nog) geen facturen.",
    "payment-discount": "betaalkorting",
    "search-gave-no-records": "Er zijn op dit moment geen facturen die voldoen aan deze criteria.",
    "title": "Facturen"
  },
  "job-request": {
    "agreement-checkbox-text": "Ik heb kennisgenomen van de voorwaarden van de <b class='plus-sign'>+&hairsp;</b> opdracht. Ik ga akkoord met de kosten van € 2,50 per gefactureerd uur bij het uitvoeren van deze opdracht. Deze kosten worden door Magnit aan mij gefactureerd en verrekend met de betaling die ik van Magnit ontvang.",
    "agreement-description": "Dit is een <b class='plus-sign'>+&hairsp;</b> opdracht waarop aanvullende voorwaarden van toepassing zijn.",
    "agreement-more-info": "Meer informatie",
    "agreement-title": "Akkoord op aanvullende voorwaarden",
    "confirmed-plus-text": "Er is akkoord gegeven op de <b class='plus-sign'>+&hairsp;</b> opdracht en de daarbij horende kosten van €2,50 per gefactureerd uur.",
    "confirmed-plus-title": "Akkoord op inhouding",
    "consent-given-to-agreement": "Ik heb kennisgenomen van de voorwaarden van de <b class='plus-sign'>+&hairsp;</b> opdracht. Ik ga akkoord met de kosten van € 2,50 per gefactureerd uur bij het uitvoeren van deze opdracht. Deze kosten worden door Magnit aan mij gefactureerd en verrekend met de betaling die ik van Magnit ontvang.",
    "plus-info-popup-text": "Dit is een <b class='plus-sign'>+&hairsp;</b> opdracht waarbij standaard een <b class='plus-sign'>+&hairsp;</b> pakket is inbegrepen. Het <b class='plus-sign'>+&hairsp;</b> pakket bestaat uit:<br><br><ul class='brainnet-ul'><li class='bullit-point'>Snelle uitbetaling binnen 15 dagen</li><li class='bullit-point'>Standaard dekking tegen het beroeps- en bedrijfsaansprakelijkheidsrisico</li></ul>De kosten voor het <b class='plus-sign'>+&hairsp;</b> pakket zijn inclusief de services van Magnit en bedragen <b>€2,50 per gefactureerd uur</b>. Deze kosten worden door Magnit aan jou gefactureerd en verrekend met de betaling die je van Magnit ontvangt.",
    "plus-info-popup-title": "<b class='plus-sign'>+&hairsp;</b> Opdracht"
  },
  "jobrequest-detail": {
    "candidate": "Kandidaat",
    "candidate-add": "Voeg een nieuwe kandidaat toe",
    "candidate-complete": "Kandidaat aanvullen",
    "candidate-not-listed": "Staat de kandidaat niet in de lijst?",
    "check-proposal": "Controleer de gegevens",
    "cla": "CAO",
    "contact-brainnet": "Wordt behandeld door",
    "contact-modal": {
      "button-text": "Contact",
      "faq-link": "onze pagina met veelgestelde vragen raadplegen",
      "text-1": "Heb je vragen over deze aanvraag? Voor meer informatie over functie-eisen, uren of tarief, kun je contact opnemen met <b>{{contactName}}</b> via <A HREF='mailto:{{contactEmailAddress}}'>{{contactEmailAddress}}</A>.",
      "text-2": "Voor vragen over het indienen van een kandidaat of het indieningsproces kun je eerst",
      "text-3": "Voor technische ondersteuning kun je een ticket indienen via ons <A HREF='https://support.magnitglobal.com/help' target='_blank'>Magnit Global Services and Support Center</A>, of bel ons supportteam via <A HREF='tel:+31 (0) 30 602 16 17'>+31 (0) 30 602 16 17</A> (maandag t/m vrijdag, van 9.00 tot 16.00 uur).",
      "title": "Contact met Magnit"
    },
    "dateofbirth": "Geboortedatum",
    "detavast": "Detavast",
    "detavast-answer": "Ja",
    "expertises": "Kennisgebieden",
    "extra-information": "Extra informatie",
    "function-description": "Functie-omschrijving",
    "hourly-rate": "Max. tarief per uur",
    "hours-per-week": "Uren per week",
    "jobrequest-expired": "De deadline voor deze aanvraag is verlopen",
    "jobrequest-required": "Er ontbreken essentiele gegevens van deze kandidaat",
    "jobrequest-saved": "De aanbieding is opgeslagen als concept",
    "location": "Locatie",
    "no-jobrequest-selected": "Er zijn geen aanvragen geselecteerd",
    "option-to-extend": "Optie tot verlengen",
    "period": "Beoogde periode",
    "placeholder-candidate": "Selecteer een kandidaat",
    "placeholder-no-candidates": "Voeg eerst een kandidaat toe",
    "propose-candidate": "Kandidaat aanbieden",
    "repetitive-jobrequest": "Dit is een herhaalde aanvraag",
    "required-experience": "Ervaring in jaren",
    "required-schooling": "Benodigde opleiding",
    "residence": "Woonplaats",
    "save-proposal": "Opslaan als concept",
    "scale": "Schaal",
    "select-candidate": "Selecteer een kandidaat",
    "self-employment-allowed": "Zzp'er toegestaan",
    "send-proposal": "Aanbieding versturen",
    "title": "Aanvraag",
    "withdraw-proposal": "Aanbieding intrekken",
    "your-proposal": "Je hebt aangeboden"
  },
  "jobrequest-plus-package-details": {
    "link": "Meer informatie",
    "text": "<b>Let op:</b> dit is een <b class='plus-sign'>+&hairsp;</b> opdracht waarbij aanvullende voorwaarden gelden."
  },
  "jobrequest-search": {
    "asap": "Z.S.M.",
    "column-deadline": "Deadline",
    "column-number": "Aanvraagnummer",
    "column-number-tooltip": "Uniek aanvraagnummer en het aantal kandidaten dat vanuit jouw organisatie is aangeboden.",
    "column-options": "Opties",
    "column-period": "Gewenste start & locatie",
    "column-position": "Functie",
    "isrepeated": "Dit is een herhaalde aanvraag"
  },
  "jobrequest-search-page": {
    "no-requests": "Er zijn op dit moment geen verborgen aanvragen."
  },
  "jobrequests-page": {
    "tab-archived": "Gearchiveerd",
    "tab-drafts": "Concepten",
    "tab-favourite": "Favorieten",
    "tab-hidden": "Verborgen",
    "tab-new": "Aanvragen",
    "tab-proposed": "Aangeboden",
    "title": "Aanvragen",
    "title-archived-proposals": "Gearchiveerde aanbiedingen",
    "title-archived-proposed": "Gearchiveerde aanbiedingen",
    "title-drafts": "Concepten",
    "title-proposed": "Aangeboden"
  },
  "legal-form": {
    "bv": "Besloten vennootschap",
    "eenmanzaak": "Eenmanzaak",
    "other": "Overige",
    "vennootschap": "Vennootschap onder firma (VOF)"
  },
  "modal-delegate-screening": {
    "label-email": "E-mail adres",
    "label-firstname": "Roepnaam",
    "label-lastname": "Achternaam",
    "label-prefix": "Tussenvoegsel (optioneel)"
  },
  "modal-supplier-company-details-send": {
    "body": "De bedrijfsgegevens worden ter controle naar ons verzonden. Weet je zeker dat je door wilt gaan?",
    "header": "Je bedrijfsgegevens worden naar Brainnet verstuurd",
    "title": "Bedrijfsgegevens verzenden"
  },
  "navigate": {
    "toassignment": "Bekijk opdracht"
  },
  "navigation": {
    "assignments": "Opdrachten",
    "contracts": "Contracten",
    "employments": "Inhuur",
    "home": "Home",
    "jobrequests": "Aanvragen",
    "overviews": "Overzichten",
    "overviews-candidates": "Kandidaten",
    "overviews-contracts": "Contracten",
    "overviews-costs": "Onkostenrapportage",
    "overviews-hours": "Urenrapportage",
    "overviews-hours-and-expenses": "Uren en onkosten",
    "overviews-invoices": "Facturen",
    "overviews-jobrequests": "Aanvragen",
    "overviews-paymentproposals": "Betaalvoorstellen",
    "reports": "Overzichten",
    "reports-candidates": "Kandidaten",
    "reports-contracts": "Contracten",
    "reports-costs": "Onkostenrapportage",
    "reports-hours": "Urenrapportage",
    "reports-invoices": "Facturen",
    "reports-jobrequests": "Aanvragen",
    "reports-paymentproposals": "Betaalvoorstellen"
  },
  "onboarding": {
    "slides": {
      "contracts": {
        "header": "Contracten",
        "subheader-1": "Heldere contracten",
        "subheader-2": "Dekking van aansprakelijkheidsrisico’s",
        "text-1": "Je kunt hier de contracten inzien en ook direct online ondertekenen als je tekenbevoegd bent.",
        "text-2": "Waar gewerkt wordt, worden fouten gemaakt. Via Magnit kun je het beroeps- en bedrijfsaansprakelijkheidsrisico laten afdekken."
      },
      "declare-expenses": {
        "header": "Onkosten declareren",
        "subheader-1": "Onkosten declareren",
        "subheader-2": "Onkostenrapportage",
        "text-1": "Je kunt hier al je onkosten of reiskosten declareren. Zodra je de onkosten hebt ingediend, kan jouw inhurende manager de gemaakte onkosten beoordelen.",
        "text-2": "Deze rapportage geeft je inzicht in al je gedeclareerde en goedgekeurde onkosten of reiskosten. In sommige gevallen is het noodzakelijk dat je deze rapportage downloadt en opstuurt."
      },
      "delegated-screenings": {
        "header": "Screeningen",
        "subheader": "Jouw Pre Employment Screening",
        "text": "Ter voorbereiding op je inzet vragen we je alle benodigde informatie aan te leveren voor de screening."
      },
      "intro": {
        "header": "Nieuw in de portal",
        "subheader": "Nieuwe mogelijkheden!",
        "text": "De portal is uitgebreid met nieuwe functionaliteiten. Klik op ‘volgende’ voor meer uitleg over de mogelijkheden."
      },
      "invoices": {
        "header": "Facturen",
        "subheader-1": "Gegarandeerde uitbetaling",
        "subheader-2": "Versnelde uitbetaling",
        "text-1": "Je kunt de facturen met bijbehorende betaaldatum hier inzien. Indien van toepassing kun je het betaalvoorstel goedkeuren.",
        "text-2": "Sneller dan de reguliere betaaltermijn je geld ontvangen? Wij bieden de mogelijkheid om jouw factuur versneld uit te betalen."
      },
      "jobrequests": {
        "header": "Aanvragen",
        "subheader-1": "Aanvragen",
        "subheader-2": "Aanbieden",
        "text-1": "Bij aanvragen vind je een overzicht van alle interessante vacatures en opdrachten. Afhankelijk van eerder gekozen vakgebieden, worden alleen de vacatures en opdrachten getoond die voor jou van toepassing zijn.",
        "text-2": "Je kunt jezelf of andere kandidaten aanbieden op interessante vacatures of opdrachten. Je kunt je CV en motivatie specifiek afstemmen per opdracht. Wij zoeken de beste kandidaten voor onze opdrachtgevers."
      },
      "manage-jobrequests": {
        "header": "Aanvragen beheren",
        "subheader": "Vakgebieden",
        "text": "Je kunt eerder gekozen vakgebieden altijd wijzigen of uitbreiden voor jou en de rest van je organisatie."
      },
      "plus-package": {
        "header": "<span class='plus-sign-xl'>+&hairsp;</span> Opdracht en <span class='plus-sign-xl'>+&hairsp;</span> pakket",
        "subheader": "<span class='plus-sign-lg'>+&hairsp;</span> Opdracht",
        "text": "Sommige opdrachten zijn voorzien van een <b class='plus-sign'>+&hairsp;</b> icoon. Dit zijn zogenoemde <b class='plus-sign'>+&hairsp;</b> opdrachten. Een <b class='plus-sign'>+&hairsp;</b> opdracht is een opdracht waarbij altijd een <b class='plus-sign'>+&hairsp;</b> pakket is inbegrepen. Het <b class='plus-sign'>+&hairsp;</b> pakket bestaat uit:<br><br><ul class='brainnet-ul'><li class='bullit-point'>Snelle uitbetaling binnen 15 dagen</li><li class='bullit-point'>Standaad dekking van het beroeps- en bedrijfsaansprakelijkheidsrisico</li></ul>De kosten voor het <b class='plus-sign'>+&hairsp;</b> pakket zijn inclusief de services van Magnit en bedragen €2,50 per gefactureerd uur. Deze kosten worden door Magnit aan jou gefactureerd en verrekend met de betaling die je van Magnit ontvangt."
      },
      "register-worked-hours": {
        "header": "Uren schrijven",
        "subheader-1": "Gewerkte uren",
        "subheader-2": "Urenrapportage",
        "text-1": "Je kunt hier al je gewerkte uren schrijven. Als je de uren hebt ingediend, kan jouw inhurende manager deze uren beoordelen.",
        "text-2": "Deze rapportage geeft je inzicht in al je gewerkte en goedgekeurde uren. In sommige gevallen is het noodzakelijk dat je deze rapportage downloadt en opstuurt."
      },
      "screenings": {
        "header": "Screeningen",
        "subheader-1": "Pre Employment Screening",
        "subheader-2": "Screening uitbesteden aan de Deskundige",
        "text-1": "Voordat de Deskundige kan worden ingezet, wordt er eerst een Pre Employment Screening uitgevoerd. Wij vragen je hier alle benodigde informatie aan te leveren.",
        "text-2": "Het is nu ook mogelijk om de screening te delen met de Deskundige. Hij of zij kan dan zelf inloggen in de portal en alle benodigde documentatie en informatie aanleveren."
      }
    }
  },
  "options": {
    "nationality": "Nationaliteit"
  },
  "page": {
    "subpage": {
      "screening-id-verification": {
        "documentnumber": "Documentnummer"
      }
    }
  },
  "password": {
    "alert-changed": "Het wachtwoord is gewijzigd.",
    "alert-not-changed": "Het wachtwoord is <ins>niet</ins> gewijzigd, probeer het opnieuw.",
    "change-instruction": "Wijzig hier je wachtwoord.",
    "change-instruction-must-differ": "Het wachtwoord moet anders zijn dan de vorige wachtwoorden en moet aan de volgende eisen voldoen:",
    "did-not-validate": "Het wachtwoord voldoet niet aan alle eisen",
    "enter-current-password": "Vul het huidig wachtwoord in",
    "enter-new-password": "Vul het nieuwe wachtwoord in",
    "has-digits": "Bevat minimaal één cijfer",
    "has-lowercase-characters": "Bevat minimaal één kleine letter",
    "has-minimum-amount-of-characters": "Bestaat uit minimaal 12 tekens",
    "has-symbols": "Bevat minimaal één speciaal karakter",
    "has-uppercase-characters": "Bevat minimaal één hoofdletter",
    "hide-password": "Verberg wachtwoord",
    "label-current-password": "Huidig wachtwoord",
    "label-new-password": "Nieuw wachtwoord",
    "label-repeat-new-password": "Herhaal nieuw wachtwoord",
    "not-enough-characters": "Het wachtwoord moet uit minimaal 12 karakters bestaan",
    "not-possible": "Het gekozen wachtwoord is niet mogelijk",
    "previous-password": "Het is niet mogelijk om een eerder gebruikt wachtwoord te kiezen",
    "repeated-password-does-not-match": "De wachtwoorden komen niet overeen",
    "repeat-new-password": "Herhaal het nieuwe wachtwoord",
    "same-as-current": "Wachtwoord is hetzelfde als het huidige wachtwoord",
    "show-password": "Toon wachtwoord",
    "strength-good": "Goed",
    "strength-very-strong": "Zeer sterk",
    "strength-weak": "Zwak",
    "wrong-password": "Het wachtwoord is onjuist."
  },
  "payment-proposal": {
    "amount-to-blocked-bankaccountnumber": "Bedrag naar G-rekening",
    "amount-to-this-account": "Bedrag naar deze bankrekening",
    "approval-failed": "Het betaalvoorstel is niet goedgekeurd. Probeer het nog eens.",
    "approval-succeeded": "Het betaalvoorstel is goedgekeurd.",
    "approve": "Betaalvoorstel goedkeuren",
    "bankaccountnumber": "Jouw bankrekeningnummer",
    "blocked-bankaccountnumber": "G-rekeningnummer",
    "blocked-by-credit-invoice-error": "Het is niet mogelijk dit betaalvoorstel goed te keuren. Keur eerst de credit betaalvoorstellen goed. <a href='overviews/payment-proposals'>Ga direct naar het betaalvoorstellenoverzicht</a>.",
    "blocked-by-credit-payment-proposal-error": "Het is niet mogelijk dit betaalvoorstel goed te keuren. Keur eerst de credit betaalvoorstellen goed. <a href='supplier/overviews/payment-proposals'>Ga direct naar het betaalvoorstellenoverzicht</a>.",
    "check-details": "Controleer de gegevens. Zijn de gegevens niet correct, neem dan contact op met de financiële administratie van Magnit.",
    "choose-payment-option": "Kies je betaaloptie:",
    "credit-proposal-exists": "Keur eerst alle credit proforma facturen goed voordat je doorgaat met het goedkeuren van debet proforma facturen",
    "details": "Details betaalvoorstel",
    "details-are-correct": "De gegevens zijn correct",
    "download": "Download betaalvoorstel",
    "early-payment": "Versneld betalen",
    "error-while-validating-invoice-number": "Er is iets misgegaan. Het factuurnummer kon niet worden gevalideerd.",
    "invoice-number": "Factuurnummer",
    "invoice-type": "Factuurtype",
    "non-unique-invoice-number-error": "Het factuurnummer is al eerder gebruikt, vul een ander factuurnummer in.",
    "no-records": "Er zijn (nog) geen betaalvoorstellen.",
    "payment-details": "Betaalgegevens",
    "payment-option": "Betaling binnen {{days}} dagen met <b>{{percentage}}% betaalkorting</b>",
    "payment-proposals": "Betaalvoorstellen",
    "percentage-to-blocked-bankaccountnumber": "Percentage naar G-rekening",
    "provide-invoice-number": "Geef een uniek factuurnummer op (max. 22 karakters)",
    "search-gave-no-records": "Er zijn op dit moment geen betaalvoorstellen die voldoen aan deze criteria.",
    "payment-option-1": "Betaling binnen {{days}} werkdag met <b>{{percentage}}% betaalkorting</b>",
    "blocked-by-bank-account-error": "Je kunt dit betaalvoorstel niet goedkeuren omdat het gevraagde bankrekeningnummer nog niet is verstrekt of gevalideerd. Controleer jouw bedrijfsgegevens.",
    "blocked-by-g-account-error": "Je kunt dit betaalvoorstel niet goedkeuren omdat het gevraagde G-rekeningnummer nog niet is verstrekt of gevalideerd. Controleer jouw bedrijfsgegevens."
  },
  "plus-package": {
    "header": "+ opdracht",
    "subheader": "+ opdracht",
    "text": "Sommige opdrachten zijn voorzien van een <b class='plus-sign'>+&hairsp;</b>icoon. Dit zijn zogenoemde <b class='plus-sign'>+&hairsp;</b><b>opdrachten</b>. Een <b class='plus-sign'>+&hairsp;</b><b>opdracht</b> is een opdracht waarbij altijd een <b class='plus-sign'>+&hairsp;</b><b>pakket</b> is inbegrepen. <br/> Het <b class='plus-sign'>+&hairsp;</b><b>pakket</b> bestaat uit: <br/> <ul><li>Snelle uitbetaling binnen 15 dagen</li> <li>Standaad dekking van het beroeps- en bedrijfsaansprakelijkheidsrisico</li></ul> De kosten voor het <b class='plus-sign'>+&hairsp;</b><b>pakket</b> zijn inclusief de services van Magnit en bedragen €2,50 per gefactureerd uur. Deze kosten worden door Magnit aan jou gefactureerd en verrekend met de betaling die je van Magnit ontvangt."
  },
  "privacy-statement": {
    "accept": "Ik ga akkoord",
    "header": "PRIVACYSTATEMENT MAGNIT",
    "instruction": "Om gebruik te maken van de portal, dient er akkoord te worden gegeven op het privacystatement.",
    "reject": "Ik ga niet akkoord"
  },
  "end-user-license-agreement": {
    "accept": "Ik ga akkoord",
    "header": "LICENTIEOVEREENKOMST VOOR EINDGEBRUIKERS",
    "instruction": "Om gebruik te maken van de portal, dient er akkoord te worden gegeven op de licentieovereenkomst voor eindgebruikers.",
    "reject": "Ik ga niet akkoord"
  },
  "professionalMutationDetails": {
    "address1": "Adres 1",
    "address2": "Adres 2",
    "address3": "Adres 3",
    "address-title": "Adres",
    "city": "Woonplaats",
    "contact-title": "Contactgegevens",
    "country-title": "Land",
    "dateOfBirth": "Geboortedatum",
    "employment-type-title": "Arbeidsrelatie",
    "empoyment-type-title": "Arbeidsrelatie",
    "firstname": "Roepnaam",
    "firstNames": "Voornamen",
    "fullname": "Volledige naam",
    "gender": "Geslacht",
    "initials": "Tussenvoegsel",
    "lastName": "Achternaam",
    "nationality": "Nationaliteit",
    "personal-details-title": "Personalia",
    "placeOfBirth": "Geboorteplaats",
    "preferredLastName": "Te gebruiken achternaam voor zakelijk e-mailadres",
    "private-email-private-title": "Privé e-mailadres",
    "readonly": "Status Deskundige gegevens",
    "status-professional-details": "Status Deskundige gegevens",
    "telephone-number": "Telefoonnummer",
    "zipCode": "Postcode"
  },
  "profile-contact": {
    "contact-brainnet": "Contact met Magnit",
    "contact-brainnet-text": "Heb je nog vragen of kunnen wij je ergens mee van dienst zijn? Neem contact op met ons Magnit Global Services & Support Center voor verdere informatie, te chatten met een virtuele of live agent, of om een supportticket in te dienen via onderstaande link:",
    "contact-brainnet-text-2": "Het Services & Support Center zal beschikbaar zijn in het Nederlands en Engels. Om je te helpen bij het navigeren door ons Support Center, kun je de video's bekijken onderaan deze pagina.",
    "important-text": "<span class='font-bold'>Belangrijk: </span>Selecteer bij het aanmaken van het ticket bij de dropdown *Systeem de optie Magnit VMS (Nederland).",
    "contact-magnit": "Contact met Magnit",
    "dutch-chamber-of-commerce-number": "KVK nummer",
    "normal-open-hours": "9.00 tot 16.00 uur",
    "openinghours": "Openingstijden bezoekadres",
    "postal-address": "Postadres",
    "public-holidays-notice": "* Openingstijden op feestdagen kunnen afwijken.",
    "service-now": {
      "overview-video": "<span class='font-bold'>Overzichtsvideo: </span>In deze video krijg je een overzicht van het Services & Support Center, inclusief hoe je kunt inloggen, chatten met een virtuele of live agent, een ondersteuningsticket kunt indienen en nog veel meer. Klik op de afbeelding hieronder om deze video te zien.",
      "registration-video": "<span class='font-bold'>Registratievideo: </span>In deze video laten we je zien hoe je een account kunt registreren in ons Services & Support Center. We raden je aan om een gebruikersaccount aan te maken voor volledige toegang tot onze zelfhulptools. Klik op de afbeelding hieronder om onze instructie video te zien.",
      "text": "Het Services & Support Center biedt een gemoderniseerde interface die gebruikers helpt bij het vinden van antwoorden, chatten met een virtuele of live agent, het openen van een case, het volgen van de status van een case of het controleren van de systeemstatus.  Selecteer bij het aanmaken van het ticket bij de drop down *Systeem de optie Magnit VMS (Nederland).",
      "title": "Het Magnit Services & Support Center"
    },
    "other-info": {
      "tab-title": "Overige contact informatie",
      "title": "Andere contact informatie pagina",
      "text": "Voor al jouw ondersteuningsvragen raden wij je aan om contact op te nemen met ons Global Support Team via het Magnit Global Services and Support Center. Hiervoor kun je gebruik maken van de link die op de bovenstaande pagina wordt verstrekt.",
      "text-2": "Als je hulp nodig hebt, kun je ons ook bereiken via telefoon of e-mail via de onderstaande contactgegevens:",
      "phone-number": "<a href='tel:+31306021617'>+31 (0) 30 602 16 17</a>"
    },
    "technical-difficulties-with-telephone-service": "We zijn momenteel telefonisch minder goed bereikbaar door een technische storing. Je kunt ons wel bereiken via e-mail.",
    "vat-number": "BTW nummer",
    "visiting-address": "Bezoekadres"
  },
  "profile-details-summary": {
    "active-workorders": "Lopende opdrachten",
    "business-organisation": "Bedrijfsvorm",
    "registered-since": "Ingeschreven sinds",
    "view-job-requests": "Aanvragen inzien"
  },
  "profile-edit-email": {
    "cancel": "Annuleren",
    "cannot-edit-modal": {
      "text": "Het is helaas niet mogelijk om het e-mailadres te wijzigen in het e-mailadres wat zojuist is ingevoerd. Neem contact op met Magnit.",
      "title": "E-mailadres wijzigen niet gelukt"
    },
    "current-password": "Huidig wachtwoord",
    "header": "E-mailadres wijzigen",
    "modal": {
      "change": "Wijzigen",
      "text": "Weet je zeker dat je het e-mailadres wilt wijzigen? Voortaan kan je alleen met dit e-mailadres inloggen.",
      "title": "E-mailadres wijzigen"
    },
    "new-email": "Nieuw e-mailadres",
    "new-email-again": "Herhaal nieuw e-mailadres",
    "save": "Opslaan",
    "save-error": "Het e-mailadres is <u>niet</u> gewijzigd.",
    "save-success": "Het e-mailadres is gewijzigd.",
    "subheader": "Toelichting",
    "text": "Het e-mailadres is nodig om toegang te krijgen tot het portal. Ook zullen we dit e-mailadres gebruiken om je te informeren als dat nodig is."
  },
  "profile-edit-language": {
    "cancel": "Annuleren",
    "dropdown-title": "Taal",
    "header": "Taalinstelling wijzigen",
    "save": "Opslaan",
    "save-error": "De taal is <u>niet</u> gewijzigd.",
    "save-success": "De taal in gewijzigd.",
    "subheader": "Toelichting",
    "text": "Wijzig hier je taalinstelling. De informatie zal voortaan in de gekozen taal worden getoond."
  },
  "profile-edit-notifications": {
    "cancel": "Annuleren",
    "header": "Notificatievoorkeuren voor aanvragen wijzigen",
    "no-option": "Nee, stuur mij geen notificaties",
    "save": "Opslaan",
    "save-error": "De notificatievoorkeuren voor aanvragen zijn <u>niet</u> gewijzigd.",
    "save-success": "De notificatievoorkeuren voor aanvragen zijn gewijzigd.",
    "subheader": "Toelichting",
    "text": "Als je een e-mail wilt ontvangen wanneer er nieuwe aanvragen zijn op jouw vakgebied, dan kan je dat hieronder aangeven. Hier kan je ook aangeven dat je geen e-mailnotificaties meer wenst te ontvangen.",
    "title": "Ik wil graag het nieuwste aanbod ontvangen via e-mail:",
    "yes-option": "Ja, stuur mij notificaties wanneer er nieuwe aanvragen zijn"
  },
  "profile-edit-password": {
    "changed": "Het wachtwoord is gewijzigd.",
    "header": "Wachtwoord wijzigen",
    "not-changed": "Het wachtwoord is niet gewijzigd, probeer het opnieuw.",
    "password-confirmation": {
      "confirm": "Wachtwoord veranderen",
      "subheader": "Weet je het zeker?",
      "text": "Je staat op het punt om je wachtwoord te veranderen. Als je dit bevestigt, word je automatisch uitgelogd en moet je opnieuw inloggen met je nieuwe wachtwoord.",
      "title": "Wachtwoord veranderen"
    },
    "subheader": "Toelichting"
  },
  "profile-expertises": {
    "at-least-one-warning": "Er moet minimaal 1 vakgebied geselecteerd zijn.",
    "header": "Toelichting vakgebieden",
    "modal": {
      "change": "Vakgebieden wijzigen",
      "text-part1": "Weet je het zeker?",
      "text-part2": "Je staat op het punt de vakgebieden te wijzigen voor iedereen binnen",
      "title": "Vakgebieden wijzigen"
    },
    "text": "Via dit formulier kun je vakgebieden wijzigen. Deze vakgebieden zijn nodig om de specifieke aanvragen in te kunnen zien en kandidaten op te kunnen aanbieden. Hebben meerderen mensen binnen jouw organisatie een portal account? Dan geldt deze wijziging ook voor hen.",
    "updated-successful": "De vakgebieden zijn gewijzigd."
  },
  "profile-faq": {
    "assignments": {
      "answer-1": "Op het moment dat je bent begonnen bij jouw opdrachtgever en de opdracht staat op niet gestart, is het contract nog niet toegevoegd.",
      "answer-10": "AMP neemt contact met jou op via sms of email. Hierin staat een link naar hun website. Je kunt via hun website een geschikt tijdstip en locatie kiezen voor de ID controle.",
      "answer-11": "Magnit werkt samen met de AMP Groep voor de uitvoering van de identiteitscontrole op locatie.",
      "answer-12": "Je kunt in de portal aangeven of je de ID controle digitaal wilt uitvoeren, op het kantoor van Magnit in Nieuwegein, of op een zelfgekozen locatie. De digitale ID controle kan direct worden uitgevoerd. Als je kiest voor een zelfgekozen locatie, neemt AMP contact met je op voor het maken van een afspraak. Als je kiest voor het kantoor van Magnit, kun je een datum en tijdvak kiezen om langs te komen op kantoor. Deze keuze wordt bevestigd via de e-mail.",
      "answer-13": "Waar gewerkt wordt, worden fouten gemaakt. Door het beroeps- en bedrijfsaansprakelijkheidsrisico af te dekken, ben je verzekerd als dit soort fouten voorkomen.",
      "answer-14": "Neem contact op met de het Client Services Team als je de digitale ID controle niet hebt kunnen afronden, bijvoorbeeld omdat je telefoon of laptop vastliep. Je kunt het Client Services Team bereiken via de contactgegevens op de opdracht.",
      "answer-2": "De verlenging van jouw opdracht wordt door de opdrachtgever aan ons doorgegeven. Op het moment dat het op beëindigd staat terwijl het wel verlengd zou worden, is de verlenging nog niet door de opdrachtgever aan ons doorgegeven en moet het nog worden verwerkt. Dit kan 2 dagen duren. ",
      "answer-3": "Een goede screening is noodzakelijk om te controleren of professionals daadwerkelijk beschikken over de juiste kwalificaties, papieren, diploma's en gecontroleerde referenties. Controle hierop voorkomt dat opdrachtgevers achteraf voor verrassingen komen te staan.",
      "answer-4": "Een screening kan worden gedeeld met de deskundigen. Dit houdt in dat de deskundige zelf alle gegevens kan invullen en uploaden.",
      "answer-5": "Op het moment dat niet alle screeningsitems kunnen worden uitgevoerd, moet eerst een voorgaand screeningsitem voltooid zijn.",
      "answer-6": "Neem contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-7": "Neem contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-8": "Wanneer je de screening kan zien, maar niet kan uitvoeren, is de screening toegewezen aan een collega binnen jouw bedrijf.",
      "answer-9": "Zodra je in de portal bij de screening hebt aangegeven dat de ID controle moet plaatsvinden op een zelfgekozen locatie, zal AMP contact met jou opnemen. Dit gebeurt via sms of e-mail.",
      "header": "Opdrachten",
      "question-1": "De opdracht staat op niet gestart, maar ik ben al wel begonnen met de opdracht. Hoe kan dat?",
      "question-10": "Hoe kan ik een afspraak maken met AMP?",
      "question-11": "Wat is AMP?",
      "question-12": "Hoe kan ik mijn ID laten controleren?",
      "question-13": "Waarom moet ik een beroeps- en bedrijfsaansprakelijkheidsverzekering hebben?",
      "question-14": "Ik heb de digitale ID controle niet kunnen afronden. Wat moet ik doen?",
      "question-2": "De opdracht staat op beëindigd, maar de opdracht wordt verlengd. Hoe kan dat?",
      "question-3": "Waarom is er een screening van toepassing?",
      "question-4": "Wat is delen met deskundige?",
      "question-5": "Ik kan bepaalde screeningonderdelen nog niet uitvoeren. Waarom niet?",
      "question-6": "Ik heb een vraag over een screening. Wat nu?",
      "question-7": "Ik heb een vraag over een document. Wat nu?",
      "question-8": "Ik kan de screening inzien maar niet uitvoeren. Hoe komt dat?",
      "question-9": "Ik kan de screening inzien maar niet uitvoeren. Hoe komt dat? "
    },
    "assignments-text": "Hier vind je de meest gestelde vragen over opdrachten, screeningen en de beroeps- en bedrijfsaansprakelijkheidsverzekering via Magnit.",
    "contracts": {
      "answer-1": "Wanneer je het contract kan inzien, maar niet kan ondertekenen, ben jij niet de juiste tekenbevoegde. Neem contact met ons op via de contactgegevens op de opdracht.",
      "answer-2": "Neem contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-3": "Neem contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-4": "Nadat de screening is afgerond, wordt het contract in orde gemaakt. Dit kan 5 dagen duren. ",
      "answer-5": "De opdrachtgever brengt ons op de hoogte van de contractverlenging. Hierna duurt het 2 dagen voordat het contract verwerkt is.",
      "answer-6": "Een verlenging van je contract wordt doorgegeven door de opdrachtgever. Jij hoeft hiervoor niks te doen. ",
      "answer-7": "Alleen de juiste tekenbevoegde mag contracten goedkeuren. Neem hiervoor contact met ons op via de contactgegevens op de opdracht.",
      "answer-8": "Alleen contracten die online zijn ondertekend vind je terug in het portal. Contracten die per email verstuurd zijn, zie je hier niet terug.",
      "header": "Contracten",
      "question-1": "Ik kan het contract wel inzien, maar ik kan het contract niet ondertekenen. Waarom is dat?",
      "question-2": "Ik ben het niet eens met het contract. Wat nu?",
      "question-3": "Er staat een fout in het contract. Wat nu?",
      "question-4": "De screening is afgerond, waarom heb ik nog geen contract ontvangen?",
      "question-5": "Mijn contract is verlengd, maar ik zie het nog niet in de portal. Wat nu?",
      "question-6": "Hoe kan ik een verlenging van mijn contract doorgeven?",
      "question-7": "Iemand anders moet het contract goedkeuren. Hoe geef ik dat door?",
      "question-8": "Ik kan bepaalde contracten niet terugvinden in de portal. Hoe kan dat?"
    },
    "contracts-text": "Hier vind je de meest gestelde vragen over het inzien en ondertekenen van contracten.",
    "hours": {
      "answer-1": "Als je nog geen uren kan schrijven, kan het zijn dat je contract nog niet rond is. Is dit wel het geval? Neem dan op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-10": "Niet iedereen kan onkosten declareren. Is dit wel afgesproken met je opdrachtgever? Neem dan contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-11": "Het is contractueel niet mogelijk om langer dan 6 maanden in het verleden uren te schrijven.",
      "answer-12": "Als je geen uren meer kan schrijven, is het contract afgelopen en is er nog geen contractverlenging gemeld. Klopt dit niet? Neem dan contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-2": "Als je nog geen onkosten kan indienen, kan het zijn dat je contract nog niet rond is. Is dit wel het geval? Neem dan op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-3": "Als de uren nog niet zijn goedgekeurd, kies je voor 'terughalen'. Zijn de uren al goedgekeurd? Bespreek dan met je opdrachtgever of je de uren in een andere week kan verrekenen. ",
      "answer-4": "Als de onkosten nog niet zijn goedgekeurd, kies je voor 'terughalen'. Zijn de onkosten al goedgekeurd? Meld dit dan aan je opdrachtgever, die kan de onkosten annuleren. ",
      "answer-5": "Neem contact op met je opdrachtgever. Als je er niet uitkomt met de opdrachtgever, kan je contact opnemen met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-6": "Wanneer je uren worden afgekeurd, wordt er een reden bij vermeld. Je kunt je uren dan opnieuw indienen op de juiste manier. ",
      "answer-7": "Neem contact op met je opdrachtgever. Als je er niet uitkomt met de opdrachtgever, kan je contact opnemen met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-8": "Niet iedereen kan kilometers declareren. Is dit wel afgesproken met je opdrachtgever? Neem dan contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-9": "Niet iedereen kan reiskosten declareren. Is dit wel afgesproken met je opdrachtgever? Neem dan contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "header": "Uren en onkosten",
      "question-1": "Ik kan nog geen uren schrijven. Wat nu?",
      "question-10": "Ik heb onkosten gemaakt, maar ik kan deze nergens declareren. Hoe komt dat?",
      "question-11": "Ik probeer uren te schrijven in het verleden, maar dat is niet mogelijk. Waarom niet? ",
      "question-12": "Ik kan geen uren meer schrijven. Hoe komt dat?",
      "question-2": "Ik kan nog geen onkosten schrijven. Wat nu?",
      "question-3": "Ik heb mijn uren onjuist ingediend. Wat kan ik doen?",
      "question-4": "Ik heb onjuiste onkosten ingediend. Wat nu?",
      "question-5": "Ik heb mijn uren ingediend, maar die worden niet goedgekeurd. Wat moet ik doen? ",
      "question-6": "Ik heb mijn uren ingediend, maar die zijn afgekeurd. Wat moet ik doen? ",
      "question-7": "De kostenplaats waar ik mijn uren op moet schrijven staat er niet tussen. Wat nu?",
      "question-8": "Ik kan mijn kilometers niet declareren. Hoe komt dat?",
      "question-9": "Ik kan mijn reiskosten niet declareren. Hoe komt dat?"
    },
    "hours-text": "Hier vind je de meest gestelde vragen over uren schrijven, uren rapportages, onkosten declareren en onkosten rapportages.",
    "insurance": {
      "answer-1": "Deze kun je melden bij Alicia. Neem contact op per telefoon op <A HREF='tel:+31 85-0004100'>+31 85-0004100</A> en per e-mail via <A HREF='mailto:magnit@alicia.insure'>magnit@alicia.insure</A>, en dan zal een medewerker van Alicia de vervolgstappen met je doornemen.",
      "answer-2": "Alicia is een insurtech (digitale verzekeraar) met een missie. Een te grote groep zzp'ers gaat namelijk onverzekerd aan het werk. Alicia lost dit probleem op door passende, flexibele verzekeringen te integreren in online platformen, zoals Magnit. Meer informatie vind je op <A HREF='https://alicia.insure/' target='_blank'>alicia.insure</A>.",
      "header": "Aansprakelijkheidsverzekering via Alicia",
      "question-1": "Waar moet ik mijn schade melden?",
      "question-2": "Wie is Alicia?",
      "text": "Hier vind je de meest gestelde vragen over de beroeps- en bedrijfsaansprakelijkheid die we aanbieden via Alicia."
    },
    "invoices": {
      "answer-1": "Neem contact met ons Global Support team op via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>.<br><b>Belangrijk:</b> Selecteer bij het aanmaken van het ticket bij de dropdown *Systeem de optie Magnit VMS (Nederland).<br><br>Voor hulp bij het gebruik van het Magnit Services and Support Center klik je <A HREF='https://youtu.be/aoLYTKiOAtE' target='_blank'>hier</A>",
      "answer-10": "Magnit werkt met de factuurvereisten zoals gesteld door de overheid. Voor meer informatie, <A HREF='https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/btw/administratie_bijhouden/facturen_maken/factuureisen/factuureisen' target='_blank'>klik hier</A>.",
      "answer-11": "De betaalvoorstellen worden goedgekeurd door één of meerdere specifiek aangewezen personen binnen jouw organisatie met de juiste rechten. Als je dit wilt wijzigen, neem dan contact op met het Client Services Team via de contactgegevens op de opdracht.",
      "answer-12": "Neem contact met ons Global Support team op via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A> of e-mail naar <A HREF='mailto:accountantsverklaringen@magnitglobal.com'>accountantsverklaringen@magnitglobal.com</A>.<br><b>Belangrijk:</b> Selecteer bij het aanmaken van het ticket bij de dropdown *Systeem de optie Magnit VMS (Nederland).<br><br>Voor hulp bij het gebruik van het Magnit Services and Support Center klik je <A HREF='https://youtu.be/aoLYTKiOAtE' target='_blank'>hier</A>",
      "answer-13": "Wanneer je een factuur opstuurt, krijg je binnen 7 dagen een bevestiging. Als je na deze periode nog geen bevestiging hebt ontvangen, kan je met ons Global Support team contact opnemen via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>.<br><b>Belangrijk:</b> Selecteer bij het aanmaken van het ticket bij de dropdown *Systeem de optie Magnit VMS (Nederland).<br><br>Voor hulp bij het gebruik van het Magnit Services and Support Center klik je <A HREF='https://youtu.be/aoLYTKiOAtE' target='_blank'>hier</A>",
      "answer-2": "Neem contact met ons Global Support team op via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>.<br><b>Belangrijk:</b> Selecteer bij het aanmaken van het ticket bij de dropdown *Systeem de optie Magnit VMS (Nederland).<br><br>Voor hulp bij het gebruik van het Magnit Services and Support Center klik je <A HREF='https://youtu.be/aoLYTKiOAtE' target='_blank'>hier</A>",
      "answer-3": "Bij de afwijzing van de factuur staat de reden voor afwijzing en wat er moet worden aangepast. Zodra je de factuur hebt aangepast, kan je hem opnieuw indienen. ",
      "answer-4": "Soms is het fijn om sneller uitbetaald te worden. Door een bepaalde korting te geven (afhankelijk van wanneer jij je geld wilt ontvangen) ontvang je sneller je geld.",
      "answer-5": "Je kan het versneld betalen aanvragen bij het goedkeuren van het betaalvoorstel. Als er geen betaalvoorstel van toepassing is, neem dan contact met ons Global Support team op via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>. Benoem in het onderwerp van de Support Request dat het om versneld betalen gaat.<br><b>Belangrijk:</b> Selecteer bij het aanmaken van het ticket bij de dropdown *Systeem de optie Magnit VMS (Nederland).<br><br>Voor hulp bij het gebruik van het Magnit Services and Support Center klik je <A HREF='https://youtu.be/aoLYTKiOAtE' target='_blank'>hier</A>",
      "answer-6": "Voordat het betaalvoorstel wordt goedgekeurd, moet wegens het verrekenen eerst het credit betaalvoorstel worden goedgekeurd. ",
      "answer-7": "De betaling kan vertraagd zijn om meerdere reden. Er moet bijvoorbeeld nog een contract getekend worden of er mist een risicoafdekking. Neem contact met ons Global Support team op via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>.<br><b>Belangrijk:</b> Selecteer bij het aanmaken van het ticket bij de dropdown *Systeem de optie Magnit VMS (Nederland).<br><br>Voor hulp bij het gebruik van het Magnit Services and Support Center klik je <A HREF='https://youtu.be/aoLYTKiOAtE' target='_blank'>hier</A>",
      "answer-8": "Je factuur wordt betaald op de betaaldatum zoals vermeld bij facturen. Als er niet op deze datum wordt betaald, ontbreekt er nog een contract of risicoafdekking. ",
      "answer-9": "Stuur je facturen naar <A HREF='mailto:facturen@magnitglobal.com'>facturen@magnitglobal.com</A>.",
      "header": "Facturen en betaalvoorstellen",
      "question-1": "Mijn betaalvoorstel klopt niet. Wat nu?",
      "question-10": "Waar moet mijn factuur uit bestaan?",
      "question-11": "Wie mag de betaalvoorstellen goedkeuren?",
      "question-12": "Ik heb vragen over de financiële risico afdekking. Met wie kan ik contact opnemen?",
      "question-13": "Ik heb een factuur gestuurd, maar nog geen bevestiging ontvangen. Wat nu?",
      "question-2": "De factuur klopt niet. Wat nu?",
      "question-3": "Mijn factuur is afgewezen. Wat moet ik nu doen?",
      "question-4": "Wat is versneld betalen?",
      "question-5": "Ik wil graag sneller betaald krijgen. Hoe regel ik dat?",
      "question-6": "Ik wil een betaalvoorstel goedkeuren, maar ik krijg de melding dat ik eerst de creditfactuur moet goedkeuren. Waarom?",
      "question-7": "De betaaldatum is verlopen, maar ik heb mijn betaling nog niet binnen. Hoe komt dat?",
      "question-8": "Wanneer wordt mijn factuur betaald?",
      "question-9": "Waar moet ik mijn factuur naar sturen? "
    },
    "invoices-text": "Hier vind je de meest gestelde vragen over facturen, factuurvereisten, betaalvoorstellen en versneld betalen.",
    "job-requests": {
      "answer-1": "Om een kandidaat aan te bieden, ga je naar 'Aanvragen' waar je de juiste aanvraag selecteert. Hier klik je op de knop: 'Kandidaat aanbieden'.",
      "answer-10": "Ga om de vakgebieden te wijzigen naar 'Profiel'. Klik op 'Vakgebieden'. Lukt dit niet? Neem dan contact met ons op via onze contactgegevens. Ga rechtsboven naar 'Ondersteuning' en klik op 'Contact'.",
      "answer-11": "Je kunt je aanmelden via 'Profiel' en dan 'Instellingen' en klik op 'E-mailnotificaties nieuwe aanvragen'. Of neem contact met ons op via onze contactgegevens. Ga rechtsboven naar 'Ondersteuning' en klik op 'Contact'.",
      "answer-12": "We streven er naar om zo snel mogelijk wat te laten horen. Zodra wij meer informatie hebben over jouw kandidaat laten we het je zo snel mogelijk weten. ",
      "answer-13": "Helaas is het niet mogelijk om na het verstrijken van de deadline nog kandidaten aan te bieden. ",
      "answer-14": "Als je het niet eens bent met een afwijzing kan je contact opnemen met het Client Services Team via de contactgegevens op de aanvraag.",
      "answer-15": "Ga linksboven naar 'Aanvragen' en klik op 'Aangeboden'. Hier kan je al je aanbiedingen inzien.",
      "answer-16": "Soms is extra informatie nodig om de kandidaat te kunnen aanbieden in het systeem van de opdrachtgever. In deze gevallen vragen wij deze extra informatie op wanneer je een kandidaat aanbiedt.",
      "answer-17": "Via de knop 'intrekken' kan je de kandidaat terugtrekken uit de aanbieding. Let op: dit kan alleen als de kandidaat nog niet is aangeboden. Wil je de kandidaat intrekken als hij/zij al is aangeboden? Neem dan contact op met het Client Services Team via de contactgegevens op de aanvraag.",
      "answer-2": "Je voegt een kandidaat toe bij het kopje 'Overzichten'. Ga naar 'Kandidaten' en klik op de knop 'Kandidaat toevoegen'. Je kan ook een kandidaat toevoegen op het moment dat je een kandidaat wilt aanbieden. Klik hiervoor op de knop: 'Voeg een nieuwe kandidaat toe'.",
      "answer-3": "Je kan een kandidaat verwijderen bij 'Overzichten'. Ga naar 'Kandidaten' en klik dan op het icoontje van een prullenbak. Let op: als een kandidaat al is aangeboden op een aanvraag kan je deze niet verwijderen totdat de kandidaat is afgewezen. ",
      "answer-4": "Ga naar 'Overzichten' en dan naar 'Kandidaten'. Klik op het potlood bij de kandidaat die je wilt wijzigen. Let op: als een kandidaat al is aangeboden op een aanvraag kan je deze niet wijzigen totdat de kandidaat is afgewezen. ",
      "answer-5": "Er zijn verschillende mogelijkheden waardoor een aanvraag niet meer zichtbaar is. De deadline kan zijn verstreken, de aanvraag is verplaatst naar favorieten of de aanvraag is verborgen. Ook kan het zijn dat de vakgebieden van de aanvraag zijn aangepast. ",
      "answer-6": "Check van tevoren of je de juiste vakgebieden hebt geselecteerd. Dit doe je via 'Profiel' en dan 'vakgebieden'. Is dit het geval, dan heb je niet de juiste rechten. Neem contact met ons op via onze contactgegevens. Ga rechtsboven naar 'Ondersteuning' en klik op 'Contact'. ",
      "answer-7": "Naast de aanvraag staat een sterretje. Klik deze aan. ",
      "answer-8": "Naast de aanvraag staat een symbool van een oogje. Klik op dit oogje om de aanvraag te verbergen. ",
      "answer-9": "Klik op 'verborgen' om de verborgen aanvragen te bekijken. Klik op het oogje om de aanvraag weer zichtbaar te maken. Let op: dit kan alleen als de deadline nog niet verstreken is.",
      "header": "Aanvragen",
      "question-1": "Hoe kan ik een kandidaat aanbieden? ",
      "question-10": "ik heb interesse in andere vakgebieden. Hoe kan ik deze wijzigen? ",
      "question-11": "Hoe kan ik mij aanmelden zodat ik de aanvragen per mail ontvang?",
      "question-12": "Ik heb een kandidaat aangeboden. Wanneer hoor ik wat?",
      "question-13": "De deadline is verstreken, maar ik wil graag nog een kandidaat aanbieden. Hoe doe ik dat?",
      "question-14": "Mijn kandidaat is afgewezen voor een aanvraag maar hier ben ik het niet mee eens. Wat kan ik doen? ",
      "question-15": "Hoe kan ik zien of mijn aanbieding is ontvangen?",
      "question-16": "Ik moet voor een aanvraag extra gegevens van mijn kandidaat invullen. Waarom is dit?",
      "question-17": "Ik heb een kandidaat toegevoegd, maar wil hem/haar weer intrekken. Hoe doe ik dit? ",
      "question-2": "Hoe voeg ik een kandidaat toe?",
      "question-3": "Hoe kan ik een kandidaat verwijderen?",
      "question-4": "Hoe kan ik de gegevens van een kandidaat wijzigen?",
      "question-5": "Wat moet ik doen als ik de aanvraag niet meer kan vinden? ",
      "question-6": "Wat moet ik doen als ik geen rechten heb voor een specifieke aanvraag? ",
      "question-7": "Hoe zet ik een aanvraag tussen mijn favorieten? ",
      "question-8": "Hoe kan ik een aanvraag verbergen?",
      "question-9": "Ik heb een aanvraag verborgen. Hoe kan ik deze weer zichtbaar maken?"
    },
    "job-requests-text": "Hier vind je de meest gestelde vragen over aanvragen, kandidaten aanbieden en kandidaten beheren.",
    "plus-package": {
      "answer-1": "Een <b class='plus-sign'>+&hairsp;</b> opdracht is een opdracht waarbij standaard een  <b class='plus-sign'>+&hairsp;</b> pakket is inbegrepen.<br>Het  <b class='plus-sign'>+&hairsp;</b> pakket bevat de volgende onderdelen:<br><br><ul class='brainnet-ul'><li class='bullit-point'>Snelle uitbetaling: binnen 15 dagen na ontvangst factuur</li><li class='bullit-point'>Afdekking van het beroeps- en bedrijfsaansprakelijkheidsrisico</li></ul>De kosten zijn inclusief de services van Magnit en bedragen €2,50 per gefactureerd uur.",
      "answer-2": "Een <b class='plus-sign'>+&hairsp;</b> opdracht is altijd inclusief een <b class='plus-sign'>+&hairsp;</b> pakket. Het <b class='plus-sign'>+&hairsp;</b> pakket bestaat uit een snelle uitbetaling binnen 15 dagen na ontvangst van je factuur, en afdekking van je beroeps- en bedrijfsaansprakelijkheidsrisico en de services van Magnit. In geval van een <b class='plus-sign'>+&hairsp;</b> opdracht heeft de betreffende klant ervoor gekozen de zoekkosten te beleggen in de keten.",
      "answer-3": "Jazeker, het blijft altijd mogelijk je kosteloos in te schrijven bij Magnit en te reageren op opdrachten. Je bent vrij om te reageren op alle opdrachten. Alleen aan <b class='plus-sign'>+&hairsp;</b> opdrachten zijn kosten verbonden. Het staat je natuurlijk vrij of je hier wel of niet op wilt reageren.",
      "answer-4": "Binnen een <b class='plus-sign'>+&hairsp;</b> opdracht ben je altijd automatisch gedekt tegen het risico van beroeps- en  bedrijfsaansprakelijkheid. Ook heb je de zekerheid dat je binnen 15 dagen na indienen van je factuur uitbetaald krijgt. Helaas zijn deze zaken niet los te koppelen van elkaar.",
      "header": "<b class='plus-sign-lg'>+&hairsp;</b> Opdrachten en <b class='plus-sign-lg'>+&hairsp;</b> pakket",
      "question-1": "Wat is een <b class='plus-sign'>+&hairsp;</b> opdracht?",
      "question-2": "Waarom moet ik betalen voor de <b class='plus-sign'>+&hairsp;</b> opdracht?",
      "question-3": "Kan ik mij wel kosteloos inschrijven bij Magnit?",
      "question-4": "In een <b class='plus-sign'>+&hairsp;</b> pakket zit afdekking voor het beroeps- en bedrijfsaansprakelijkheidsrisico. Dit heb ik niet nodig/die heb ik al gedekt. Kan ik dit eraf halen?",
      "tab-title": "<b class='plus-sign'>+&hairsp;</b> Opdrachten en <b class='plus-sign'>+&hairsp;</b> pakket",
      "text": "Hier vind je de meest gestelde vragen over <b class='plus-sign'>+&hairsp;</b> opdrachten en het <b class='plus-sign'>+&hairsp;</b> pakket."
    },
    "search": "Zoeken",
    "search-header": "Veelgestelde vragen",
    "search-text": "Hier vind je de meest gestelde vragen per onderwerp. Je kunt ook zoeken via de zoekbalk.",
    "search-textbox": "Begin direct met zoeken",
    "settings": {
      "answer-1": "Ga rechtsboven naar 'Profiel' en klik op 'instellingen'. Hier vind je het kopje 'wachtwoord wijzigen'.",
      "answer-2": "Ga rechtsboven naar 'Profiel' en klik op 'instellingen'. Hier vind je het kopje 'Notificatievoorkeuren voor aanvragen wijzigen' waar je je voorkeuren kan aanpassen. ",
      "answer-3": "Neem contact met ons op via onze algemene contactgegevens. Ga rechtsboven naar 'Ondersteuning' en klik op 'Contact'.",
      "answer-4": "Marconibaan 1A, 3439 MR Nieuwegein. ",
      "answer-5": "Ga rechtsboven naar 'Profiel' en klik op 'instellingen'. Hier vind je het kopje 'e-mailadres wijzigen'.",
      "header": "Instellingen",
      "question-1": "Hoe kan ik mijn wachtwoord wijzigen?",
      "question-2": "Hoe kan ik de e-mails stopzetten voor aanvragen? ",
      "question-3": "Ik heb een vraag die hier niet tussen staat. Hoe neem ik contact met jullie op?",
      "question-4": "Wat is het adres van Magnit?",
      "question-5": "Ik wil mijn emailadres wijzigen, hoe doe ik dat?"
    },
    "settings-text": "Hier vind je de meest gestelde vragen over het wijzigen van je instellingen, zoals je wachtwoord of e-mailadres."
  },
  "profile-overview": {
    "tab-company-details": "Bedrijfsgegevens",
    "tab-contact": "Contact",
    "tab-details": "Bedrijfsgegevens",
    "tab-expertises": "Vakgebieden",
    "tab-faq": "Veelgestelde vragen",
    "tab-overview": "Overzicht",
    "tab-settings": "Instellingen"
  },
  "profile-overview-default": {
    "contact-subtitle": "Neem contact op als je vragen hebt",
    "contact-title": "Contact",
    "expertises-subtitle": "Selecteer de vakgebieden waarvoor je aanvragen wilt zien",
    "expertises-title": "Vakgebieden",
    "faq-subtitle": "Direct antwoord op veelgestelde vragen",
    "faq-title": "Veelgestelde vragen",
    "overview-company-details": "Bedrijfsgegevens",
    "overview-company-details-subtitle": "Overzicht van jouw geregistreerde gegevens",
    "settings-subtitle": "Wijzig je accountinstellingen",
    "settings-title": "Instellingen"
  },
  "profile-profesional": {
    "anwsers": "Direct antwoord op veelgestelde vragen",
    "personal-data": "Persoonlijke gegevens",
    "personal-data-overview": "Overzicht van jouw geregistreerde gegevens",
    "profession": "Vakgebieden",
    "questions": "Veelgestelde vragen"
  },
  "profile-settings": {
    "account-email-text": "Wijzig hier je e-mailadres:",
    "account-language-header": "Taal",
    "account-language-text": "Wijzig je taalinstelling",
    "account-modify": "Wijzigen",
    "account-notifications-header": "E-mailnotificaties nieuwe aanvragen",
    "account-notifications-text": "Wijzig hier je notificatie instellingen",
    "account-password-header": "Wachtwoord",
    "account-password-text": "Wijzig hier je wachtwoord",
    "privacy-and-cookie-statement": "Privacy- en cookiestatement",
    "privacy-confirmation": {
      "confirm": "Akkoord intrekken",
      "subheader": "Weet je het zeker?",
      "text": "Je staat op het punt om je akkoord op het privacystatement in te trekken. Zonder akkoord kan er helaas geen gebruik worden gemaakt van het portal en wordt je automatisch uitgelogd. Klik op annuleren om het akkoord te behouden.",
      "title": "Akkoord intrekken"
    },
    "privacy-cookie-link": "https://www.brainnet.nl/cookiestatement/",
    "privacy-cookiestatement": "Cookiestatement",
    "privacy-privacy-link": "https://www.brainnet.nl/privacystatement/",
    "privacy-privacystatement": "Privacystatement",
    "privacy-subheader": "Het privacystatement en cookiestatement",
    "privacy-text": "Door op onderstaande link te klikken heb je volledige inzage in het privacystatement en het cookiestatement van Magnit.",
    "privacy-waiver-part1": "Je hebt op",
    "privacy-waiver-part2": "akkoord gegeven op het privacystatement van Magnit.",
    "privacy-withdraw-consent": "Akkoord intrekken"
  },
  "profile-supplier": {
    "add-extra-tradename": "Extra handelsnaam toevoegen",
    "basic": {
      "addition": "Toevoeging",
      "business-address": "Vestigingsadres",
      "city": "Plaats",
      "company-form": "Bedrijfsvorm",
      "company-form-organisation": "Organisatie levert professionals",
      "company-form-self-employed": "Zelfstandige",
      "company-name": "Bedrijfsnaam",
      "country": "Land",
      "legal-form": "Rechtsvorm",
      "number": "Nummer",
      "postal-address": "Postadres",
      "postal-code": "Postcode",
      "postal-same-as": "Gelijk aan vestigingsadres",
      "registered-name": "Statutaire naam",
      "street": "Straat",
      "telephone-email": "E-mail en telefoon",
      "telephone-number": "Algemeen telefoonnummer",
      "title": "Bedrijfsinformatie",
      "trade-names": "Handelsnamen"
    },
    "coc": {
      "certificate": "Upload KvK uittreksel",
      "certificate-text": "Upload hier alle Kamer van Koophandel uittreksels van toepassing.<br>Een KvK uittreksel mag niet ouder dan 3 maanden zijn.",
      "country": "Vestigingsland",
      "number": "KvK-nummer",
      "registered-at": "Ingeschreven bij KvK vestiging te",
      "title": "Kamer van Koophandel inschrijving (KvK)"
    },
    "coc-number-error": "Het ingevulde KvK-nummer is niet correct",
    "coc-registration-end-date-in-past": "Het bedrijf dat bij dit KvK-nummer hoort, is niet meer ingeschreven bij de Kamer van Koophandel.",
    "company-details-submitted": "Bedankt! De bedrijfsgegevens zijn verstuurd naar Magnit.",
    "contact": {
      "contact-main-can-sign": "De contactpersoon is tekenbevoegd",
      "contact-main-is-financial": "De contactpersoon is verantwoordelijk voor de financiële administratie",
      "contact-person": "Contactpersoon",
      "email-address": "E-mailadres",
      "financial": "Financiële administratie",
      "firstname": "Voornaam",
      "lastname": "Achternaam",
      "lastname-according-to-coc": "Achternaam (conform KvK uittreksel)",
      "main": "(Hoofd-) Contactpersoon",
      "position": "Functie",
      "prefix": "Tussenvoegsel (optioneel)",
      "signer": "Tekenbevoegde(n) volgens KvK",
      "telephone-email": "E-mailadres en telefoonnummer",
      "telephone-number": "Telefoonnummer",
      "title": "Contactpersonen en tekenbevoegden"
    },
    "contact-add": "Voeg een contactpersoon toe",
    "control": {
      "send": "Verzenden",
      "title": "Controleren en verzenden"
    },
    "draft-saved": "De gegevens zijn opgeslagen als concept",
    "financial": {
      "bank-account": "Bankrekeningnummer",
      "gaccount": "G-rekeningnummer",
      "gaccount-certificate": "Bewijs van G-rekening",
      "iban": "(IBAN) Bankrekeningnummer",
      "payroll-taxes": "Fiscaal aansluitnummer loonheffingen",
      "title": "Financiële gegevens",
      "vat-number": "Fiscaal aansluitnummer omzetbelasting"
    },
    "information-sent": "Jouw aanvulling op de bedrijfsgegevens zijn verstuurd naar Magnit",
    "needs-information": "De bedrijfsgegevens kunnen nog niet worden verzonden. Nog niet alle verplichte velden zijn ingevuld.",
    "standards": {
      "certificate": "SNA certificaat",
      "intermediaries-act-text": "In de Wet allocatie arbeidskrachten door intermediairs (Waadi) staat dat bedrijven die ‘bedrijfsmatig arbeidskrachten ter beschikking stellen’ een registratieplicht hebben. Als je bedrijfsmatig arbeidskrachten ter beschikking stelt, moet er een aantekening staan bij jouw bedrijfsregistratie in het Handelsregister. Geef hieronder aan of jouw bedrijf Waadi geregistreerd is en of het uitlenen van arbeidskrachten bedrijfsmatig of niet bedrijfsmatig gebeurt.",
      "intermediaries-act-title": "WAADI registratie",
      "intermediaries-registered": "Waadi geregistreerd?",
      "intermediaries-registered-purpose": "Doel",
      "intermediaries-registered-purpose-company": "Bedrijfsmatig",
      "intermediaries-registered-purpose-non-company": "Niet bedrijfsmatig",
      "labour-standards-registered": "SNA Geregistreerd?",
      "labour-standards-text": "Het SNA-keurmerk is het keurmerk voor de uitzendsector en is er om de risico’s van inleners van arbeid en opdrachtgevers van werk te beperken. Als jouw bedrijf geregistreerd staat in het Register Normering Arbeid wordt er 25% van het factuurbedrag gestort op de G-rekening, in plaats van 55%. Je kunt jouw registratie hieronder uploaden.",
      "labour-standards-title": "SNA registratie",
      "title": "WAADI en SNA registratie"
    },
    "status": {
      "complete": "Bedrijfsgegevens compleet",
      "incomplete": "Bedrijfsgegevens incompleet",
      "rejected": "Bedrijfsgegevens afgekeurd",
      "waiting-for-approval": "Bedrijfsgegevens worden beoordeeld"
    },
    "status-page": {
      "change-company-details": "Wijzig bedrijfsgegevens",
      "date-received": "Datum aangeleverd",
      "date-reviewed": "Datum beoordeling",
      "header": "Status",
      "provided-by": "Aangeleverd door",
      "provided-information": "Verstrekte informatie",
      "reason-for-rejection": "Reden van afkeuren",
      "rejected": "Afgekeurd",
      "waiting-for-review": "Wacht op beoordeling"
    },
    "summary": {
      "basic-title": "Basisgegevens",
      "signer": "Tekenbevoegde",
      "telephone-number": "Telefoonnummer"
    },
    "tax-risk-mitigation": {
      "expert-flow-header": "Er wordt een deskundige in loondienst ter beschikking gesteld",
      "expert-flow-text": "Er kan gekozen worden voor de volgende opties:",
      "g-account": "G-rekeningnummer",
      "g-account-certificate": "Bewijs van G-rekening",
      "g-account-certificate-required": "Een bewijs van G-rekening is verplicht.",
      "g-account-requested": "G-rekening is aangevraagd",
      "g-account-requested-additional-info": "<b>Belangrijk: </b>Een G-rekening kun je aanvragen bij de Belastingdienst. Let hierbij op dat het aanvragen van de G-rekening ongeveer 3-6 weken duurt. Magnit verzoekt je vriendelijk om tijdig de G-rekening aan te vragen, zodat de uitbetaling van facturen niet vertraagd. Zolang Magnit niet beschikt over het G-rekeningnummer van jouw bedrijf, kunnen de facturen niet betaald worden.",
      "indemnity-declaration": "Vrijwaringsbeschikking",
      "information-not-needed": "Je hebt aangegeven dat je geen andere deskundigen inzet dan jezelf. Deze informatie hoef je daarom niet aan te leveren.",
      "method-accountants-statement": "Ons bedrijf is Waadi niet-bedrijfsmatig geregistreerd en zal accountantsverklaringen sturen na ieder kwartaal",
      "method-accountants-statement-disabled": "Deze optie kan alleen worden gekozen als jouw bedrijf Waadi niet-bedrijfsmatig is geregistreerd. Dit kan worden aangegeven bij het tabje 'WAADI en SNA registratie'.",
      "method-accountants-statement-download": "Hierbij een voorbeeld.",
      "method-accountants-statement-explanation": "Na afloop van ieder kwartaal is de Accountantsverklaring model 3000A nodig en moet deze worden aangeleverd.",
      "method-g-account": "Storting op de G-rekening",
      "method-g-account-insufficient": "Vul het nummer van de G-rekening in of vink aan dat deze is aangevraagd.",
      "method-indemnity-declaration": "Geen storting G-rekening, want ons bedrijf beschikt over een vrijwaringsbeschikking",
      "model-service-agreement": "Modelovereenkomst leverancier",
      "model-service-agreement-confirmation": "Leverancier bevestigt dat de modelovereenkomst gebruikt wordt voor al haar zzp'ers",
      "model-service-agreement-download": "Hierbij een voorbeeld van een modelovereenkomst.",
      "self-employed-professional-flow-header": "Er wordt een zzp'er ter beschikking gesteld",
      "self-employed-professional-flow-text": "Magnit ontvangt graag de modelovereenkomst van tussenkomst die de leverancier aangaat met de zzp'er. Het gaat hierbij om het sjabloon dat gebruikt wordt. In plaats dat voor iedere zzp'er afzonderlijk de modelovereenkomst wordt opgevraagd, vraagt Magnit de modelovereenkomst op die de leverancier gebruikt voor al haar zzp'ers.",
      "title": "Fiscale risico's afdekking",
      "workforce-both": "Zowel een deskundige in loondienst (werknemer) als een ZZP'er (ondernemer)",
      "workforce-expert": "Deskundige in loondienst (werknemer)",
      "workforce-self-employed-professional": "ZZP'er (ondernemer)",
      "workforce-text": "Ons bedrijf stelt de volgende arbeidskrachten ter beschikking:",
      "workforce-title": "Arbeidskrachten"
    },
    "tooltip-g-account-number": "Dit is alleen van toepassing als jouw bedrijf Waadi geregistreerd is en het uitlenen van arbeidskrachten bedrijfsmatig gebeurt.",
    "tooltip-payroll-taxes": "Dit is alleen van toepassing als je arbeidskrachten in loondienst hebt.",
    "tooltip-vat-number": "Vul hier het btw-nummer in. Heb je een eenmanszaak, dan vul je het btw-identificatienummer in."
  },
  "proposaldraft-search-page": {
    "column-candidate": "Kandidaat en functie",
    "column-deadline": "Deadline",
    "column-jobrequest": "Aanvraagnummer",
    "column-last-change": "Laatste wijziging",
    "column-options": "Opties",
    "column-status": "Status"
  },
  "proposal-search-buttons": {
    "delete-part-one": "Je staat op het punt om de aanbieding van",
    "delete-part-two": "in te trekken. De aanbieding wordt omgezet naar een conceptaanbieding. Dit kan niet meer ongedaan worden gemaakt.",
    "proposal-has-been-withdrawn": "De aanbieding is ingetrokken. Je vindt de aanbieding bij <a href='/supplier/jobrequests/drafts'>concepten</a>.",
    "proposal-withdrawn": "De aanbieding is ingetrokken. Je vindt de aanbieding bij <a href='/jobrequests/drafts'>concepten</a>.",
    "we-will-withdraw-your-proposal": "We gaan de aanbieding voor je intrekken."
  },
  "proposal-search-page": {
    "column-candidate": "Kandidaat en functie",
    "column-deadline": "Deadline",
    "column-information": "Informatie",
    "column-jobrequest": "Aanvraagnummer",
    "column-last-change": "Laatste wijziging",
    "column-options": "Opties",
    "column-status": "Status",
    "withdraw": "Intrekken",
    "withdraw-proposal": "Aanbieding intrekken",
    "withdraw-title": "Aanbieding intrekken"
  },
  "proposalstatus": {
    "active": "Ontvangen door Magnit",
    "concept": "Concept",
    "expired": "Vervallen",
    "inactive": "Afgewezen door Magnit",
    "inactive-closed": "Aanvraag gesloten",
    "intake": "Intakegesprek",
    "intake-rejected": "Afgewezen na intakegesprek",
    "placed": "Opdracht gestart",
    "placement-to-follow": "Geselecteerd",
    "presented": "Voorgesteld aan opdrachtgever",
    "presented-rejected": "Afgewezen door opdrachtgever",
    "to-be-presented": "Voorgesteld aan opdrachtgever",
    "unspecified": "In behandeling"
  },
  "proposalstatus-information": {
    "active": "De kandidaat wordt beoordeeld door Magnit",
    "declinedafterintake": "De kandidaat is afgewezen door de opdrachtgever, n.a.v. het intakegesprek",
    "inactive": "De kandidaat is afgewezen door Magnit",
    "inactivejobrequestclosed": "De aanvraag is niet langer beschikbaar",
    "intakeplanned": "De kandidaat is uitgenodigd voor een intakegesprek bij de opdrachtgever, klik op deze regel voor de intakegegevens",
    "notspecified": "De kandidaat wordt in behandeling genomen door Magnit",
    "onassignment": "De kandidaat gaat werken bij de opdrachtgever.",
    "placementwillfollow": "De kandidaat is geselecteerd door de opdrachtgever. Er zal nu een Pre-Employment Screening plaatsvinden",
    "proposaldeclined": "De kandidaat is afgewezen door de opdrachtgever",
    "proposed": "De kandidaat wordt voorgesteld aan de opdrachtgever",
    "unspecified": "De kandidaat wordt in behandeling genomen door Magnit",
    "waitingforproposal": "De kandidaat wordt voorgesteld aan de opdrachtgever"
  },
  "propose-candidate": {
    "deletedraft": {
      "body": "Je staat nu op het punt om deze concept aanbieding te verwijderen.<br>Deze actie kun je niet ongedaan maken.",
      "title": "Concept verwijderen"
    },
    "duplicatedraft": {
      "body": "Er bestaat al een concept aanbieding op deze aanvraag, met deze kandidaat.<br>Wil je deze concept aanbieding openen?",
      "confirm": "Ga naar concept",
      "title": "Concept aanbieding gevonden"
    }
  },
  "propose-candidate-page": {
    "availableper": "Beschikbaar per",
    "candidate-already-proposed": "Je hebt de geselecteerde kandidaat reeds aangeboden op deze aanvraag. Je kunt een kandidaat niet nogmaals aanbieden op dezelfde aanvraag.",
    "candidate-already-proposed-button-text": "Ga naar aanbieding",
    "cvdownload": "CV",
    "employmentrelationship": "Arbeidsrelatie",
    "group-1": "Beschikbaarheid en tarief",
    "group-2": "Arbeidsrelatie kandidaat",
    "group-3": "Opleidingsniveau",
    "group-4": "Functievereistenprofiel",
    "group-5": "Tussenschakels",
    "group-6": "Motivatie en CV",
    "group-7": "Detavast vereisten",
    "highestlevelofeducation": "Hoogst genoten opleiding",
    "hourlyrate": "Uurtarief",
    "hoursperweek": "Uren per week",
    "intermediaries": "Bedrijfsnaam tussenschakel (optioneel)",
    "jobrequest-is-no-longer-active": "De aanvraag is niet meer actief. Het is niet mogelijk om een kandidaat aan te bieden.",
    "margins": "Marge tussenschakel (optioneel)",
    "motivation": "Motivatietekst",
    "plannedvacation": "Geplande vakanties",
    "salaryIndication": "Salarisindicatie (bruto maandsalaris)",
    "salaryIndicationProposal": "Indicatie van salaris"
  },
  "screeningactions": {
    "afgerond": "Afgerond",
    "brainnet": "Magnit",
    "cancelled": "Geannuleerd",
    "complete": "Afgerond",
    "deskundige": "Deskundige",
    "geannuleerd": "Geannuleerd",
    "professional": "Deskundige",
    "supplier": "Leverancier"
  },
  "screening-big-check-page": {
    "big-number": "BIG nummer",
    "header": "BIG registratie",
    "title": "BIG registratie"
  },
  "screening-document-upload-page": {
    "title": "Opdracht"
  },
  "screening-id-verification": {
    "alert-saved-success": "Bedankt! Wij zullen dit zo snel mogelijk beoordelen."
  },
  "screening-id-verification-page": {
    "alert-saved-success": "Bedankt! Wij zullen dit zo snel mogelijk beoordelen.",
    "appointment-id-check": "Afspraak identiteitscontrole",
    "bsn": "Burgerservicenummer",
    "date": "Datum",
    "date-and-time": "Datum en tijdstip",
    "date-of-issue": "Datum van afgifte",
    "date-time-title": "Op welke datum en tijdstip kan de identificatie plaatsvinden?",
    "document-details": "Document details",
    "documentnumber": "Documentnummer",
    "documenttype": "Documenttype",
    "from": "om",
    "identity-card": "Identiteitskaart",
    "id-verification-not-applicable-text": "Voor deze opdracht hoeft er geen ID controle uitgevoerd te worden.",
    "id-verification-not-applicable-title": "ID controle niet van toepassing",
    "location": "Locatie",
    "location-abroad": "De opdracht is buiten Nederland",
    "location-abroad-text": "Er is aangegeven dat een identiteitscontrole in Nederland niet mogelijk is. Magnit zal contact met je opnemen voor een alternatieve identiteitscontrole.",
    "location-abroad-underlying-title": "Alternatieve ID controle",
    "location-amp": "Op een zelf te kiezen locatie in Nederland",
    "location-amp-text": "Er is gekozen voor identiteitscontrole op locatie. Voor de uitvoering van de identiteitscontrole op locatie werkt Magnit samen met AMP Groep. AMP neemt contact op via SMS of e-mail met de gegevens die zijn ingevuld bij 'Deskundige gegevens' om een afspraak te maken op een gewenst moment en zelf gekozen locatie. Afspraken kunnen gemaakt worden van maandag tot en met zaterdag, ook in de avonduren.",
    "location-brainnet": "Bij Magnit in Nieuwegein",
    "location-brainnet-underlying-title": "ID controle op locatie",
    "location-online": "Digitaal controleren",
    "location-online-delegate-text": "Er is gekozen om de ID controle digitaal uit te voeren. De ID controle wordt gestart na Stap 3.",
    "location-online-recommended-label": "Aanbevolen",
    "location-online-undelegate-text": "Er is gekozen om de ID controle digitaal uit te voeren. Na stap 3 wordt een e-mail verstuurd naar jouw kandidaat om de ID controle uit te voeren. Zorg ervoor dat je jouw kandidaat hier vooraf over informeert.",
    "location-online-underlying-title": "Digitale ID controle",
    "passport": "Paspoort",
    "place-issued": "Plaats van afgifte",
    "select-id-type-identity-card": "Identiteitskaart",
    "select-id-type-passport": "Paspoort",
    "step-appointment": {
      "choose-location": "Kies één van de volgende opties voor de identificatie",
      "explanation-eu": "Magnit is wettelijk verplicht om de identiteit van een Deskundige aan de hand van een geldig paspoort of ID (geen rijbewijs) vast te stellen.",
      "explanation-non-eu": "Magnit is wettelijk verplicht om de geldigheid van de werkvergunning van de Deskundige te controleren. Dit gebeurt aan de hand van een geldig paspoort of ID (geen rijbewijs) en het burgerservicenummer.",
      "title": "Afspraak inplannen"
    },
    "step-check": {
      "text": "De onderstaande gegevens, aangeleverd in een eerdere screening, zijn nog steeds correct.",
      "title": "Controleren en verzenden"
    },
    "step-document-details": {
      "details-earlier-screening-choice-no": "Nee, ik wil de gegevens aanpassen",
      "details-earlier-screening-choice-text": "Zijn bovengenoemde gegevens nog juist?",
      "details-earlier-screening-choice-yes": "Ja, deze gegevens zijn correct",
      "details-earlier-screening-text": "In een eerdere screening zijn onderstaande documentgegevens aan ons doorgegeven.",
      "select-id-type-eu": "Selecteer het document waarmee de identificatie zal plaatsvinden. Identificatie is niet mogelijk met een rijbewijs.",
      "select-id-type-non-eu": "De identiteit wordt gecontroleerd aan de hand van het paspoort. Vul onderstaande gegevens in.",
      "title": "Documentgegevens"
    },
    "step-four": {
      "explanation": "Er is gekozen om de ID controle digitaal uit te voeren. Voor het uitvoeren van de digitale ID controle maakt Magnit gebruik van Rabobank Identity Services. Voor de controle moet er een foto van het identiteitsbewijs worden geüpload of worden gemaakt met een webcam of de camera van een mobiele telefoon. Daarna wordt er een korte opname van het gezicht gemaakt. Op basis van deze foto’s en video wordt de identiteit van de Deskundige gecontroleerd. De gemaakte foto’s en video worden niet bewaard.",
      "notes": "Let op - Voor het uitvoeren van de digitale controle is het volgende nodig:\n\n- Een laptop/pc met webcam of een mobiele telefoon/tablet met camera en minimaal iOS 10.3 of Android.\n- Het identiteitsbewijs dat hoort bij de gegevens die zijn ingevuld bij Stap 1.\n",
      "start-button": "Start de digitale ID controle",
      "step-title": "Id Controle",
      "title": "Digitale ID controle"
    },
    "step-one": {
      "choose-location": "Kies één van de volgende opties voor de identificatie",
      "explanation-eu": "Magnit is wettelijk verplicht om de identiteit van een Deskundige aan de hand van een geldig paspoort of ID (geen rijbewijs) vast te stellen.",
      "explanation-non-eu": "Magnit is wettelijk verplicht om de geldigheid van het paspoort van de Deskundige te controleren. Dit gebeurt aan de hand van een geldig paspoort en het burgerservicenummer.",
      "title": "Afspraak inplannen"
    },
    "steps-title": "Afspraak inplannen",
    "steps-title-readonly": "Alleen lezen",
    "step-three": {
      "brainnet-address": "Marconibaan 1a",
      "brainnet-zipcode-and-city": "3439 MR Nieuwegein",
      "title": "Controleren en verzenden"
    },
    "step-two": {
      "select-id-type-eu": "Selecteer het document waarmee de identificatie zal plaatsvinden. Identificatie is niet mogelijk met een rijbewijs.",
      "select-id-type-non-eu": "De identiteitscontrole zal plaatsvinden aan de hand van het paspoort. Vul onderstaande gegevens in.",
      "title": "Documentgegevens"
    },
    "time": "Tijdstip",
    "title": "Opdracht",
    "undelegate-screening-alert-saved-success": "Bedankt! Wij hebben de e-mail verstuurd naar de deskundige.",
    "valid-until": "Geldig tot",
    "work-permit": "Werkvergunning"
  },
  "screening-integrity-check": {
    "former-employers-added-success-alert": "Bedankt! Wij zullen jouw ex-werkgevers of opdrachtgevers vragen om een integriteitsoordeel te geven.",
    "success-alert": "Bedankt! Wij zullen de Deskundige het integriteitsonderzoek zo snel mogelijk toesturen."
  },
  "screening-integrity-check-page": {
    "add-employer": "Geef minimaal 1 ex-werkgever of opdrachtgever op.",
    "add-extra-reference": "Extra ex-werkgever of opdrachtgever toevoegen",
    "comments": "Opmerkingen",
    "comments-explanation": "Zijn er bijzonderheden waar we rekening mee moeten houden?",
    "company-name": "Bedrijfsnaam",
    "consent-text": "Ik geef Magnit toestemming om bovengenoemde personen te benaderen met de vraag een oordeel te geven over mijn integriteit.",
    "consent-title": "Toestemming voor integriteitsonderzoek",
    "email": "E-mailadres",
    "ex-employer-or-client": "Ex-werkgever of opdrachtgever",
    "explanation": "Voor de werkgever waar jij gaat werken doen wij een integriteitsonderzoek. Hiervoor benaderen wij ex-werkgevers en klanten voor een integriteitsverklaring. We vragen je om toestemming te geven voor het integriteitsonderzoek zodat wij jouw gegevens mogen delen met klanten en ex-werkgevers zodat ze de verklaring kunnen ondertekenen.",
    "name-contact": "Naam contactpersoon",
    "phone-number": "Telefoonnummer",
    "step-one": {
      "title": "Integriteitscheck"
    },
    "steps-title": "Integriteitsonderzoek",
    "step-three": {
      "explanation": "Controleer de opgegeven gegevens en geef toestemming voor het benaderen van de genoemde bedrijven en personen voor een integriteitsonderzoek.",
      "title": "Controleren en verzenden"
    },
    "step-two": {
      "explanation": "Vul hier de gegevens van ex-werkgevers en klanten in die we mogen benaderen voor een integriteitscheck.",
      "title": "Ex-werkgevers of opdrachtgevers opgeven"
    }
  },
  "screening-integrity-check-send-page": {
    "explanation": "Een vast onderdeel van de pre-employment screening is het integriteitsonderzoek. Hierbij vragen wij ex-werkgevers/opdrachtgevers om een betrouwbaarheidsverklaring af te geven over de deskundige.<br />De deskundige levert contactinformatie aan over de ex-werkgevers/opdrachtgevers. Magnit vraagt via e-mail alle opgegeven ex-werkgevers/opdrachtgevers om een betrouwbaarheidsverklaring.<br /><br />Het verzoek aan de deskundige om contactinformatie aan te leveren wordt naar het volgende e-mailadres gestuurd:",
    "send-to-professional": "Verstuur naar Deskundige",
    "steps-title": "Integriteitscheck",
    "title": "Toelichting"
  },
  "screening-integrity-check-status-page": {
    "add-extra-employer": "Extra ex-werkgever toevoegen",
    "column-contact-details": "Contactgegevens",
    "column-date-sent": "Verzoek verzonden op",
    "column-employer": "Werkgever of opdrachtgever",
    "consent-explanation-professional": "Je hebt op {{date}} toestemming gegeven voor het integriteitsonderzoek. We hebben je ex-werkgevers en klanten geïnformeerd over de betrouwbaarheidsverklaring. We adviseren je om ook zelf contact op te nemen met de opgegeven ex-werkgevers en klanten, om het onderzoek zo snel mogelijk af te kunnen ronden.",
    "consent-explanation-supplier": "Hieronder vind je de voortgang van de Deskundige voor het integriteitsonderzoek. We hebben de e-mail verstuurd naar: {{email}}",
    "consent-explanation-supplier-delegated-screening": "Hieronder vind je de voortgang van de Deskundige voor het integriteitsonderzoek.",
    "former-employers-explanation": "Hieronder staan de ex-werkgevers en klanten die zijn doorgegeven aan ons. Je ziet per contactpersoon of er een betrouwbaarheidsverklaring is afgegeven.",
    "former-employer-status-email-sent": "E-mail verstuurd",
    "former-employer-status-negative": "Negatief integriteitsoordeel",
    "former-employer-status-page-visited": "Pagina bezocht",
    "former-employer-status-positive": "Posifief integriteitsoordeel",
    "former-employer-status-refused": "Wilt geen oordeel geven",
    "former-employer-status-reminded": "Herinneringse-mail verstuurd",
    "header-consent": "Toestemming integriteitsonderzoek",
    "header-explanation": "Toelichting",
    "header-provided-information": "Status Integriteitsonderzoek",
    "header-status-former-employers": "Status ex-werkgevers of opdrachtgevers",
    "status-email-sent": "E-mail verstuurd",
    "status-finished": "Gereed",
    "status-page-edited": "Aangepast",
    "status-page-visited": "Pagina bezocht",
    "status-sent": "Verstuurd",
    "title": "Status integriteitsonderzoek"
  },
  "screeningItem": {
    "big": {
      "readonly": "Status BIG registratie"
    },
    "download": {
      "downloadable": "Downloadbaar document:"
    },
    "id-check": {
      "readonly": "Status Identiteitscontrole"
    },
    "integrity-check": {
      "readonly": "Status Integriteitsonderzoek"
    }
  },
  "screening-item-status": {
    "action-needed": "Actie vereist",
    "action-required": "Actie vereist",
    "action-required-by-expert": "Actie vereist door deskundige",
    "action-required-from-professional": "Actie vereist door Deskundige",
    "completed": "Afgerond",
    "declined-action-required": "Afgekeurd, actie vereist",
    "declined-action-required-from-professional": "Afgekeurd, actie vereist door Deskundige",
    "waiting-for-integrity-statement": "Wacht op het integriteitsoordeel",
    "waiting-for-review-by-brainnet": "Wordt gecontroleerd door Magnit"
  },
  "screening-liability-insurance": {
    "alicia-download-link": "toelichting op het beroeps- en bedrijfsaansprakelijkheidsrisico",
    "alicia-download-text-1": "Lees ook de uitgebreide ",
    "alicia-download-text-2": ". Meer informatie vind je in het platform van Alicia.",
    "alicia-explanation": "Voor dit onderdeel in de screening werken wij samen met een partner. Door op de onderstaande knop te klikken, ga je door naar het platform van Alicia. Daar vragen wij je om je verzekeringsblad te uploaden om er zeker van te zijn dat jouw verzekering voldoende dekking biedt. Als je niet de juiste verzekering hebt, kun je je als zzp'er aansluiten bij de beroeps- en bedrijfsaansprakelijkheidsverzekering die Alicia als bedrijf heeft afgesloten.\n\nLet op: Je bent altijd verantwoordelijk voor je eigen verzekering. In het geval je als tussenpersoon een zzp’er ter beschikking stelt, dien je je eigen verzekering te uploaden.",
    "consent-withholding-sentence": "Ik heb de toelichting gelezen en accepteer het beroeps- en bedrijfsaansprakelijkheidsrisico.",
    "i-have-my-own-insurance-sentence": "Ik heb een beroeps- en bedrijfsaansprakelijkheidsverzekering die de bovengenoemde bedragen dekt.",
    "insurance-through-brainnet": "Ik wil mij aansluiten bij de beroeps- en bedrijfsaansprakelijkheidsverzekering van Magnit.",
    "insured-amount-corporate-liability": "Verzekerd bedrag bedrijfsaansprakelijkheid:",
    "insured-amount-professional-liability": "Verzekerd bedrag beroepsaansprakelijkheid:",
    "please-state-how-you-cover-the-above-mentioned-risk": "Geef aan hoe je het bovengenoemde risico dekt:",
    "proceed-to-alicia": "Verder naar Alicia",
    "submit-succeeded-message": "Bedankt! Wij zullen dit zo snel mogelijk beoordelen.",
    "the-client-demands-this-coverage": "Voor {{clientName}} moet de beroeps- en bedrijfsaansprakelijkheidsverzekering de volgende bedragen dekken:",
    "the-following-polis-is-uploaded": "De volgende polis is geupload",
    "you-have-insured-the-risk-through-brainnet": "Je hebt het bovenstaande risico voor deze opdracht verzekerd via Magnit."
  },
  "screening-professional-details-edit-page": {
    "add-address": "Extra adresregel toevoegen",
    "addition": "Toevoeging (optioneel)",
    "address": "Adres",
    "address-title": "Adres",
    "calling-name": "Roepnaam",
    "city": "Woonplaats",
    "complete-name": "Volledige naam",
    "contact-title": "Contactgegevens",
    "control-title": "Controleren en verzenden",
    "country": "Land",
    "date-of-birth": "Geboortedatum",
    "email-incorrect": "Indien het e-mailadres niet correct is, neem dan contact op met Magnit.",
    "employment-relationship": "Arbeidsrelatie",
    "firstnames": "Voornamen",
    "house-number": "Huisnummer",
    "initials": "Initialen",
    "lastname": "Achternaam",
    "personal-title": "Personalia",
    "phone-number": "Telefoonnummer",
    "place-of-birth": "Geboorteplaats",
    "postal-code": "Postcode",
    "preferred-name": "Te gebruiken achternaam voor zakelijk e-mailadres (indien afwijkend)",
    "preferred-name-tooltip": "Op moment dat er verschillende achternamen zijn, kan er gekozen worden welke achternaam gebruikt mag worden voor het creëren van een account (zakelijke emailadres).",
    "prefix": "Tussenvoegsels (optioneel)",
    "private-email": "Privé e-mailadres",
    "professional-details-updated-success": "Bedankt! De persoonlijke gegevens van de Deskundige zijn aangeleverd.",
    "street": "Straat",
    "title": "Vul Deskundige gegevens aan",
    "title-step-four": "Controleren en verzenden",
    "title-step-one": "Arbeidsrelatie",
    "title-step-three": "Contactgegevens",
    "title-step-two": "Personalia"
  },
  "screeningremarks": {
    "contract-waiting-to-be-signed": "Contract wacht op ondertekening",
    "do-many-actions": "Meerdere acties uitvoeren",
    "do-screening-action": "Screening actie uitvoeren",
    "payment-proposal-waiting-on-approval": "Betaalvoorstel wacht op goedkeuring",
    "wont-be-extended": "Wordt niet verlengd"
  },
  "screeningstatus": {
    "cancelled": "Geanulleerd",
    "completed": "Afgerond",
    "initiated": "Wordt opgestart",
    "open": "Open",
    "rejected": "Afgekeurd",
    "sufficient": "Voldoende voor de opdracht"
  },
  "secondee-edit-page": {
    "professional-details-deleted-success": "De kandidaat is verwijderd.",
    "professional-details-updated-success": "Hartelijk dank. De kandidaatgegevens zijn opgeslagen.",
    "step1-address": "Adres",
    "step1-city": "Woonplaats",
    "step1-country": "Land",
    "step1-dateofbirth": "Geboortedatum",
    "step1-default-text": "Onderstaande gegevens zijn verplicht om een kandidaat aan te kunnen bieden op een aanvraag.",
    "step1-given-name": "Roepnaam",
    "step1-lastname": "Achternaam",
    "step1-personal-details-title": "Persoonsgegevens",
    "step1-postalcode": "Postcode",
    "step1-prefix": "Tussenvoegsel (optioneel)",
    "step1-title": "Kandidaat gegevens",
    "step2-addition": "Toevoeging (optioneel)",
    "step2-contact-details-title": "Contactgegevens",
    "step2-default-text": "Bij sommige opdrachtgevers zijn onderstaande kandidaatgegevens nodig om de kandidaat aan te kunnen bieden. Deze gegevens zijn ook nodig tijdens het screeningsproces.",
    "step2-email": "E-mailadres (optioneel)",
    "step2-firstnames": "Voornamen (optioneel)",
    "step2-gender": "Geslacht (optioneel)",
    "step2-housenumber": "Huisnummer (optioneel)",
    "step2-initials": "Initialen (optioneel)",
    "step2-nationality": "Nationaliteit (optioneel)",
    "step2-personal-details-title": "Persoonsgegevens",
    "step2-placeofbirth": "Geboorteplaats (optioneel)",
    "step2-street": "Straat (optioneel)",
    "step2-telephone": "Telefoonnummer (optioneel)",
    "step2-title": "Aanvullende gegevens",
    "step3-title": "Controleren en verzenden",
    "title": "Kandidaat toevoegen",
    "title-edit": "Kandidaat aanpassen"
  },
  "secondee-search-buttons": {
    "cannot-edit-assigned-to-multiple-requests": "Het is niet mogelijk om de gegevens van <b>{{fullName}}</b> te wijzigen, omdat deze kandidaat is aangeboden op verschillende aanvragen.",
    "cannot-edit-assigned-to-one-request": "Het is niet mogelijk om de gegevens van <b>{{fullName}}</b> te wijzigen, omdat deze kandidaat is aangeboden op <a href={{activeJobRequestId}}>deze aanvraag</a>.",
    "cannot-remove-assigned-to-multiple-requests": "Het is niet mogelijk om <b>{{fullName}}</b> te verwijderen, omdat deze kandidaat is aangeboden op verschillende aanvragen.",
    "cannot-remove-assigned-to-one-request": "Het is niet mogelijk om <b>{{fullName}}</b> te verwijderen, omdat deze kandidaat is aangeboden op <a href={{activeJobRequestId}}>deze aanvraag</a>.",
    "remove-candidate": "Je staat op het punt om <b>{{fullName}}</b> te verwijderen. Weet je het zeker? Je kunt deze actie niet ongedaan maken."
  },
  "secondee-search-page": {
    "edit-title": "Kandidaat gegevens wijzigen niet mogelijk",
    "remove": "Kandidaat verwijderen",
    "remove-secondee": "Verwijderen",
    "remove-title": "Kandidaat verwijderen"
  },
  "secondees-page": {
    "button-add": "Kandidaat toevoegen",
    "column-city": "Woonplaats",
    "column-dateOfBirth": "Geboortedatum",
    "column-firstName": "Roepnaam",
    "column-lastName": "Achternaam",
    "column-options": "Opties",
    "column-postal-code": "Postcode",
    "empty-search": "Er zijn geen kandidaten die voldoen aan deze criteria.",
    "search-gave-no-records": "Er zijn nog geen kandidaten toegevoegd. Voeg een nieuwe kandidaat toe via de knop 'Kandidaat toevoegen'.",
    "title": "Kandidaten overzicht"
  },
  "submitted-proposals-page": {
    "intake-details": {
      "header": "Intakegegevens",
      "location": "Locatie",
      "meeting-partners": "Gesprekspartner(s)",
      "time": "Tijdstip"
    },
    "processing-text": "De kandidaat wordt in behandeling genomen door Magnit",
    "proposalfailure": "Het aanbieden van {{fullName}} is mislukt. Klik <a href='/jobrequests/drafts/{{draftId}}'>hier</a> om nogmaals aan te bieden.",
    "proposal-failure": "Het aanbieden van {{fullName}} is mislukt. Klik <a href='/supplier/jobrequests/drafts/{{draftId}}'>hier</a> om nogmaals aan te bieden.",
    "status-being-processed": "In behandeling"
  },
  "support": {
    "additional-services": "Aanvullende diensten",
    "additional-services-part": {
      "subtitle1": "Aanvullende diensten",
      "text1": "Bij Magnit willen we leveranciers en zzp’ers graag ontzorgen. Het is daarom mogelijk om via ons je beroeps- en bedrijfsaansprakelijkheidsrisico te laten afdekken. Ook kun je je extra snel laten uitbetalen. Zo kun je je vooral focussen op je opdracht, wij regelen de rest."
    },
    "additional-services-subtitle": "Informatie over Aanvullende diensten en Plus",
    "fast-payment": {
      "subtitle1": "Versneld betalen",
      "text1": "Magnit hanteert de betalingstermijn van de klant. Als je sneller je geld wil ontvangen, is het in veel gevallen mogelijk de optie 'versneld betalen' te gebruiken. Afhankelijk van de gewenste versnelde betalingstermijn, geef je korting op het totaalbedrag van je factuur. De exacte betalingstermijn verschilt per klant en wordt aangegeven in de portal. Je hebt de mogelijkheid om per opdracht te kiezen voor versneld betalen. In het geval er niet is gekozen voor versneld betalen bij een opdracht, is het mogelijk om voor die opdracht per factuur te kiezen."
    },
    "insurance": {
      "about-alicia": {
        "subsection-text": "Je kunt Alicia bereiken per telefoon op <A HREF='tel:+31 85-0004100'>+31 85-0004100</A> en per e-mail via <A HREF='mailto:magnit@alicia.insure'>magnit@alicia.insure</A>. Op werkdagen is Alicia van 9:00 tot 17:00 bereikbaar.",
        "subsection-title": "Alicia staat voor je klaar",
        "text": "Alicia is een insurtech (digitale verzekeraar) met een missie. Een te grote groep zzp'ers gaat namelijk onverzekerd aan het werk. Alicia lost dit probleem op door passende, flexibele verzekeringen te integreren in online platformen, zoals Magnit. Meer informatie vind je op <A HREF='https://alicia.insure/' target='_blank'>alicia.insure</A>.",
        "title": "Over Alicia"
      },
      "claims": {
        "subsection-text": "Deze kun je melden bij Alicia. Neem contact op per telefoon op <A HREF='tel:+31 85-0004100'>+31 85-0004100</A> en per e-mail via <A HREF='mailto:magnit@alicia.insure'>magnit@alicia.insure</A>, dan zal een medewerker van Alicia de vervolgstappen met je doornemen.",
        "subsection-title": "Heb je een schade?",
        "title": "Claims"
      },
      "coverage": {
        "covered-text": "<ul class='brainnet-ul'><li class='bullit-point'>Schade aan personen</li> <li class='bullit-point'>Schade aan andermans spullen</li> <li class='bullit-point'>Schade die ontstaat door een beroepsfout, zoals een rekenfout of verkeerd advies</li> <li class='bullit-point'>Schade die ontstaat door een stagiair(e) of uitzendkracht</li> <li class='bullit-point'>Hulp en kosten als jij aansprakelijk wordt gesteld</li></ul>",
        "covered-title": "Wat is er wel gedekt?",
        "insured-sum-business-liability": "Voor bedrijfsaansprakelijkheid:<br><ul class='brainnet-ul'><li class='bullit-point'>Bij schade aan personen of andermans spullen is het verzekerde bedrag €2.500.000 per aanspraak, met maximaal €10.000.000 per jaar voor alle certificaathouders samen</li> <li class='bullit-point'>Het eigen risico is €500 per schade aan spullen</li></ul>",
        "insured-sum-professional-indemnity": "Voor beroepsaansprakelijkheid:<br><ul class='brainnet-ul'><li class='bullit-point'>Bij beroepsfouten is het verzekerde bedrag €1.000.000 per aanspraak, met maximaal €5.000.000 per jaar voor alle certificaathouders samen</li> <li class='bullit-point'>Het eigen risico is €2.500 per schade</li></ul>",
        "insured-sum-title": "Voor welk bedrag ben je gedekt?",
        "not-covered-text": "<ul class='brainnet-ul'><li class='bullit-point'>Andermans spullen waarmee je werkt, of die je leent of huurt</li> <li class='bullit-point'>Eigen spullen</li> <li class='bullit-point'>Fraude en andere strafbare feiten</li> <li class='bullit-point'>Motorrijtuigen</li> <li class='bullit-point'>Opzet</li> <li class='bullit-point'>Seksuele gedragingen</li> <li class='bullit-point'>Schade door beleggingsadviezen en vermogensbeheer</li> <li class='bullit-point'>Schade door het overtreden van intellectueel eigendomsrecht</li> <li class='bullit-point'>Schade door aantasting van het milieu</li> <li class='bullit-point'>Schade door opzettelijk verkeerd handelen</li> <li class='bullit-point'>Schade door strafbare activiteiten, zoals fraude</li> <li class='bullit-point'>Uitbesteden van werk</li></ul>",
        "not-covered-title": "Wat is er niet gedekt?",
        "professional-liability-text": "Dekt jouw bedrijfs- en beroepsaansprakelijkheid. Zo ben je beschermd bij rekenfouten, verkeerde adviezen, als je iemands spullen beschadigt of iemand zich door jou bezeert.",
        "professional-liability-title": "Zakelijke aansprakelijkheid",
        "terms-and-conditions": "Wil je een compleet overzicht van wat er wel en niet gedekt is? <A HREF='https://aliciabenefits.com/magnit/' target='_blank'>Bekijk dan de polisvoorwaarden via deze link</A>.",
        "text": "In het geval je als zzp'er een verzekering bij Alicia afsluit, ben je als volgt gedekt.",
        "title": "Dekking"
      },
      "introduction": {
        "title": "Algemeen",
        "text": "Waar gewerkt wordt, worden fouten gemaakt. Iedereen zal zich hierin herkennen en op zich is dit ook geen probleem. Indien personen die via Magnit werken schade veroorzaken bij de inlenende partij, kan deze inlenende partij Magnit aansprakelijk stellen voor de gevolgschade.<br><br>Contractueel kan Magnit deze schade verhalen op de schadeveroorzakende partij, lees “de Opdrachtnemer”. Een dergelijke claim kan de financiële positie van de Opdrachtnemer drastisch verslechteren of zelfs een faillissement tot gevolg hebben.<br><br>Omdat dit een ongewenste situatie is, werkt Magnit samen met het bedrijf Alicia. Zodoende weten we altijd of jouw verzekering geschikt is voor het werk dat je gaat doen.<br><br>Daarnaast biedt Alicia zzp'ers die via Magnit werken de mogelijkheid om een passende verzekering aan te schaffen. Meer informatie hierover vind je <A HREF='https://aliciabenefits.com/magnit/' target='_blank'>op de website van Alicia</A>."
      },
      "subtitle": "Informatie over zakelijke aansprakelijkheidsverzekeringen",
      "title": "Verzekering"
    },
    "premium-suppliers": {
      "menu-title": "<span class='plus-sign'>+&hairsp;</span> Opdrachten",
      "subtitle1": "<b>Wat is een <span class='plus-sign'>+&hairsp;</span> opdracht?</b>",
      "subtitle2": "<b>Standaard dekking tegen beroeps- en bedrijfsaansprakelijkheidsrisico</b>",
      "subtitle3": "<b>Snelle uitbetaling</b>",
      "text1": "Een <b class='plus-sign'>+&hairsp;</b> opdracht is een opdracht waarbij standaard een <b class='plus-sign'>+&hairsp;</b> pakket is inbegrepen.<br>Het <b class='plus-sign'>+&hairsp;</b> pakket bevat de volgende onderdelen:<br><br><ul class='brainnet-ul'><li class='bullit-point'>Snelle uitbetaling binnen 15 dagen</li><li class='bullit-point'>Standaard dekking tegen het beroeps- en bedrijfsaansprakelijkheidsrisico</li></ul>De kosten voor een <b class='plus-sign'>+&hairsp;</b> pakket zijn inclusief de services van Magnit en bedragen €2,50 per gefactureerd uur. Deze kosten worden door Magnit aan jou gefactureerd en verrekend met de betaling die je van Magnit ontvangt.",
      "text2": "Beroepsaansprakelijkheid is aansprakelijkheid voor beroepsfouten in jouw werk of het werk van je werknemers.<br>Bedrijfsaansprakelijkheid is aansprakelijkheid voor materiële schade of letselschade aan personen veroorzaakt door jou of één van je werknemers. Bij een <b class='plus-sign'>+&hairsp;</b> opdracht is het beroeps- en bedrijfsaansprakelijkheidsrisico voor de betreffende opdracht gedekt via de verzekering van Magnit.",
      "text3": "Bij een <b class='plus-sign'>+&hairsp;</b> opdracht geldt een uitbetaling binnen 15 dagen na ontvangst van de factuur. Wil je nog sneller uitbetaald worden? Dan kun je gebruikmaken van een versnelde uitbetaling. Afhankelijk van de gewenste versnelde betalingstermijn, geef je korting op het totaalbedrag van je factuur. De exacte betalingstermijn verschilt per klant en wordt aangegeven in de portal. Je hebt de mogelijkheid om per factuur te kiezen voor versneld betalen.",
      "title": "<span class='plus-sign-lg'>+&hairsp;</span> Opdrachten"
    },
    "professional-and-business-liability-risks": {
      "button-more-info": "Meer informatie",
      "subtitle1": "Dekking beroeps- en bedrijfsaansprakelijkheid",
      "text1": "Beroepsaansprakelijkheid is de aansprakelijkheid voor beroepsfouten in je werk of in het werk van je werknemers. Bedrijfsaansprakelijkheid is aansprakelijkheid voor materiële schade of persoonlijk letsel veroorzaakt door jou of een van je werknemers."
    },
    "support": "Ondersteuning"
  },
  "test": "test",
  "textarea-field": {
    "characters": "tekens"
  },
  "validation": {
    "required": "Is verplicht"
  },
  "forgotPasswordHeader": "Wachtwoord vergeten",
  "forgotPasswordText": "Wachtwoord kwijt of vergeten? Vul hieronder het e-mailadres in en we sturen zo snel mogelijk een e-mail om het wachtwoord opnieuw aan te maken.",
  "emailPlaceholder": "E-mailadres",
  "invalidEmail": "Het e-mailadres is ongeldig, vul een geldig e-mailadres in.",
  "emptyEmail": "Dit veld is verplicht",
  "sendButton": "Verzenden",
  "emailSentHeader": "De e-mail is verstuurd",
  "emailSentText": "De e-mail om een nieuw wachtwoord aan te maken is verstuurd naar het opgegeven e-mailadres als het e-mailadres bij ons bekend is.",
  "notReceivedHeader": "Geen e-mail ontvangen?",
  "notReceivedText": "Heb je binnen enkele minuten geen e-mail ontvangen, controleer dan de ongewenste e-mail of spam inbox.<br><br>Het kan ook zijn dat het e-mail adres niet bestaat binnen onze administratie. Controleer ook goed de spelling van het e-mailadres. "
}
